import { Grid, MenuItem, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedLabelField,
  setSelectedSourceFile,
  setSelectedValueField,
} from "../../../app/features/addConfigVar/addConfigVarSlice";

const SourceFileOptions = ({ loader }) => {
  const dispatch = useDispatch();
  const { sourceFiles, fileColumns, selectedLabelField, selectedValueField } =
    useSelector((state) => state.addConfigVar);
  return (
    <>
      {!loader && sourceFiles.length && (
        <Grid item>
          <TextField
            required
            label="Select input source"
            select
            fullWidth
            name="inputSource"
            InputLabelProps={{ shrink: "normal" }}
            onChange={(e) => dispatch(setSelectedSourceFile(e.target.value))}
          >
            {sourceFiles.map((inputSource) => (
              <MenuItem key={inputSource.value} value={inputSource.name}>
                {inputSource.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}

      {!!fileColumns.length && (
        <Grid item mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                label="Select label field"
                select
                name="inputSource"
                InputLabelProps={{ shrink: "normal" }}
                value={selectedLabelField}
                onChange={(e) =>
                  dispatch(setSelectedLabelField(e.target.value))
                }
              >
                {fileColumns.map((inputSource) => (
                  <MenuItem key={inputSource} value={inputSource}>
                    {inputSource}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                select
                fullWidth
                label="Select value field"
                name="inputSource"
                InputLabelProps={{ shrink: "normal" }}
                value={selectedValueField}
                onChange={(e) =>
                  dispatch(setSelectedValueField(e.target.value))
                }
              >
                {fileColumns.map((inputSource) => (
                  <MenuItem key={inputSource} value={inputSource}>
                    {inputSource}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SourceFileOptions;
