import {
  Fade,
  Backdrop,
  Modal,
  Grid,
  Box,
  CloseIcon,
  DesktopDatePicker,
  Stack,
  LocalizationProvider,
  AdapterDateFns,
  TextField,
  Button,
} from "../../common/muiImports";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { Typography } from "@material-ui/core";
import { clearLogs } from "../../../api/runStatus";
import { toastError, toastSuccess } from "../../common/toast";
import { useDispatch } from "react-redux";
import { clearLogsAction } from "../../../app/features/runStatus/runStatusSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 1,
  maxHeight: "90vh",
  overflowY: "auto",
};

const ClearLogsModal = ({ showClearLogsModal, handleClose }) => {
  const [fromDate, setFromDate] = useState(startOfDay(subDays(new Date(), 7)));
  const [toDate, setToDate] = useState(endOfDay(new Date()));

  const dispatch = useDispatch();

  const handelFromDate = (date) => {
    setFromDate(startOfDay(date));
  };

  const handleToDate = (date) => {
    setToDate(endOfDay(date));
  };

  const handleClearLogs = async () => {
    console.log("clicked here");
    try {
      const payload = {
        from: fromDate,
        to: toDate,
      };
      const { data } = await clearLogs(payload);
      dispatch(clearLogsAction(payload));
      toastSuccess(data.message);
      handleClose();
    } catch (err) {
      toastError(err.response?.data.message || err.message);
      handleClose();
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      "&::-webkit-scrollbar": {
        width: `8px`,
        backgroundColor: `#F5F5F5`,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
        backgroundColor: `#ffff`,
        borderRadius: `8px`,
      },

      "&::-webkit-scrollbar-thumb": {
        backgroundImage: `-webkit-linear-gradient(90deg,
      rgb(255, 255, 255) 0%,
      rgb(212, 212, 212) 25%,
      transparent 100%,
      rgb(194, 194, 194) 75%,
      transparent)`,
        borderRadius: `10px`,
      },
    },
  }));
  const classes = useStyles();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showClearLogsModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showClearLogsModal}>
          <Box sx={style}>
            <CloseIcon
              onClick={handleClose}
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            />
            <Grid
              container
              spacing={2}
              className={classes.root}
              flexDirection="column"
            >
              <Grid item>
                <Typography variant="h6">Clear Logs</Typography>
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Stack spacing={3} direction="row">
                      <DesktopDatePicker
                        label="From"
                        inputFormat="MM/dd/yyyy"
                        value={fromDate}
                        disableFuture
                        onChange={handelFromDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <DesktopDatePicker
                        label="To"
                        inputFormat="MM/dd/yyyy"
                        value={toDate}
                        onChange={handleToDate}
                        renderInput={(params) => <TextField {...params} />}
                        sx={{ ml: 2 }}
                      />
                    </Stack>
                  </Box>
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleClearLogs}>
                  Clear
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ClearLogsModal;
