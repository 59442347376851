import { Route } from "react-router";
import AddConfigVar from "../components/ConfigVar/AddConfigVar";
import EditConfigVar from "../components/ConfigVar/EditConfigVar";
import AdminRoute from "../components/HOC/AdminRoute";
import ProtectedRoute from "../components/HOC/ProtectedRoute";
import Layout from "../components/layout/layout";
import ConfigVar from "../pages/ConfigVar";

const ConfigVarRoutes = [
  <Route
    path="/configVar"
    key="/configVar"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <ConfigVar />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,

  <Route
    path="/configVar/add-configVar"
    key="/configVar/add-configVar"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <AddConfigVar />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,

  <Route
    path="/configVar/edit-configVar/:id"
    key="/configVar/edit-configVar/:id"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <EditConfigVar />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
];

export default ConfigVarRoutes;
