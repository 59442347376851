import { http } from "./https";

export const createFlowVariant = async (payload) => {
  const response = await http.post("flow-variant", payload);
  return response;
};

export const getVariantsByFlowId = async (flowId) => {
  const response = await http.get(`flow-variant/${flowId}/variants`);
  return response;
};
