import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "./components/layout/layout";
import LoginLayout from "./components/layout/LoginLayout";
import MonitorLayout from "./components/layout/MonitorLayout";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import { useEffect } from "react";
import Register from "./pages/Register";
import Monitor from "./components/monitor/monitor";
import AdminRoute from "./components/HOC/AdminRoute";
import io from "socket.io-client";
import { useDispatch } from "react-redux";
import {
  addNewRunStatus,
  addNewTaskStatus,
  updateRunStatus,
  updateTaskStatus,
} from "./app/features/runStatus/runStatusSlice";
import ConfigVarRoutes from "./routes/ConfigVarRoutes";
import ConnectionRoutes from "./routes/ConnectionRoutes";
import TaskRoutes from "./routes/TaskRoutes";
import FlowRoutes from "./routes/FlowRoutes";
import ScheduleRoutes from "./routes/ScheduleRoutes";
import { REACT_APP_WEBSOCKET_URL } from "./config/environment";
import FileExplorerRoutes from "./routes/FileExplorerRoutes";
import ProtectedRoute from "./components/HOC/ProtectedRoute";
import Users from "./pages/Users";
function App() {
  const socket = io(REACT_APP_WEBSOCKET_URL);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("connecting to ws");
    socket.on("connect", () => {
      console.log("connection established");
    });

    socket.on("runStatusAdded", (data) => {
      console.log("runStatus added socket =>", data);
      dispatch(addNewRunStatus(data));
    });

    socket.on("runStatusUpdated", (data) => {
      dispatch(updateRunStatus(data));
    });

    socket.on("taskStatusAdded", (data) => {
      console.log("task added socket =>", data);
      dispatch(addNewTaskStatus(data));
    });

    socket.on("taskStatusUpdated", (data) => {
      console.log("task updated socket data =>", data);
      dispatch(updateTaskStatus(data));
    });
  }, [socket, dispatch]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <Navigate replace to="/monitor" />
          </Layout>
        }
      />

      {ConnectionRoutes}
      <Route
        path="/users/register-user"
        element={
          <Layout>
            <AdminRoute>
              <ProtectedRoute>
                <Register />
              </ProtectedRoute>
            </AdminRoute>
          </Layout>
        }
      />

      <Route
        path="/users"
        element={
          <Layout>
            <AdminRoute>
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            </AdminRoute>
          </Layout>
        }
      />

      {TaskRoutes}

      {FlowRoutes}

      {ScheduleRoutes}

      {ConfigVarRoutes}

      {/* TODO uncomment this after new file explorer is tested */}
      {FileExplorerRoutes}

      <Route
        path="/login"
        element={
          <LoginLayout>
            <Login />
          </LoginLayout>
        }
      />

      <Route
        path="/monitor"
        element={
          <ProtectedRoute>
            <MonitorLayout>
              <Monitor />
            </MonitorLayout>
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
