import { toast } from "react-toastify";

/**
 * Display toast success message
 * @param {string} msg Message to display on toast
 * @returns {toast} toast.success
 */
export const toastSuccess = (msg) => {
  return toast.success(msg, { autoClose: 400 });
};

/**
 * Display toast error message
 * @param {string} msg Message to display on toast
 * @returns {toast} toast.error
 */
export const toastError = (msg) => {
  return toast.error(msg, { autoClose: 400 });
};
