import { Route } from "react-router";
import AdminRoute from "../components/HOC/AdminRoute";
import ProtectedRoute from "../components/HOC/ProtectedRoute";
import Layout from "../components/layout/layout";
import AddTask from "../components/task/AddTask";
import EditTask from "../components/task/EditTask";
import Task from "../pages/Task";
import ViewDetails from "../pages/ViewDetails";

const TaskRoutes = [
  <Route
    path="/task"
    key="/task"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <Task />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
  <Route
    path="/task/edit-task/:id"
    key="/task/edit-task/:id"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <EditTask />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
  <Route
    path="/task/view-task/:id"
    key="/task/view-task/:id"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <ViewDetails />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
  <Route
    path="/task/:add-task"
    key="/task/:add-task"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <AddTask />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
];

export default TaskRoutes;
