import { createSlice } from "@reduxjs/toolkit";
import { endOfDay, startOfDay, subDays } from "date-fns";
import dayjs from "dayjs";

const initialState = {
  runStatuses: [],
  selectedRunStatus: null,
  fromDate: startOfDay(subDays(new Date(), 7)),
  toDate: endOfDay(new Date()),
  taskStatuses: [],
  selectedTaskStatus: null,
  taskLog: "",
};

export const runStatusSlice = createSlice({
  name: "runStatus",
  initialState,
  reducers: {
    setRunStatuses: (state, action) => {
      state.runStatuses = action.payload;
      state.selectedRunStatus = state.runStatuses[0];
    },
    setSelectedRunStatus: (state, action) => {
      const found = state.runStatuses.find(
        (runStatus) => runStatus._id === action.payload
      );
      state.selectedRunStatus = found;
      state.taskStatuses = state.taskStatuses.filter(
        (taskStatus) => taskStatus.runStatus === found._id
      );
    },
    filterFromDate: (state, action) => {
      state.fromDate = action.payload;
    },
    filterToDate: (state, action) => {
      state.toDate = action.payload;
    },
    addNewRunStatus: (state, action) => {
      state.selectedRunStatus = action.payload;
      state.runStatuses = [action.payload, ...state.runStatuses];
      state.taskStatuses = state.taskStatuses.filter(
        (taskStatus) => taskStatus.runStatus === action.payload._id
      );
    },
    updateRunStatus: (state, action) => {
      const found = state.runStatuses.find(
        (el) => el._id === action.payload._id
      );
      const indexOfFound = state.runStatuses.indexOf(found);
      state.runStatuses[indexOfFound] = action.payload;
    },
    setTaskStatuses: (state, action) => {
      state.taskStatuses = action.payload;
      state.selectedTaskStatus = action.payload[0];
      state.taskLog = action?.payload[0]?.taskLog || "";
    },
    setSelectedTaskStatus: (state, action) => {
      state.selectedTaskStatus = state.taskStatuses.find(
        (el) => el._id === action.payload
      );
      state.taskLog = state?.selectedTaskStatus?.taskLog || "";
    },
    addNewTaskStatus: (state, action) => {
      if (action.payload.runStatusId === state.selectedRunStatus._id) {
        state.taskStatuses = [action.payload.taskStatus, ...state.taskStatuses];
        state.selectedTaskStatus = action.payload.taskStatus;
        state.taskLog = action.payload.taskLog;
      }
    },
    updateTaskStatus: (state, action) => {
      if (action.payload.runStatusId === state.selectedRunStatus._id) {
        const foundIndex = state.taskStatuses.findIndex(
          (taskStatus) => taskStatus._id === action.payload.taskStatus._id
        );
        state.taskStatuses[foundIndex] = action.payload.taskStatus;
        state.taskLog = state.taskStatuses[foundIndex].taskLog;
      }
    },
    clearLogsAction: (state, action) => {
      const { from, to } = action.payload;

      state.runStatuses = state.runStatuses.filter((runStatus) => {
        const startTime = dayjs(runStatus.startTime);
        const isBetween =
          dayjs(startTime).isAfter(from) && dayjs(startTime).isBefore(to);

        // clear task logs of the runStatus
        if (isBetween) {
          state.taskStatuses = state.taskStatuses.filter(
            (taskStatus) => taskStatus.runStatus !== runStatus._id
          );
        }

        return !isBetween;
      });

      state.taskLog = "";
    },
  },
});

export const {
  filterFromDate,
  filterToDate,
  setRunStatuses,
  setSelectedRunStatus,
  addNewRunStatus,
  updateRunStatus,
  setTaskStatuses,
  setSelectedTaskStatus,
  addNewTaskStatus,
  updateTaskStatus,
  clearLogsAction,
} = runStatusSlice.actions;

export default runStatusSlice.reducer;
