import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../field/loader";
import { addConfigVariable, getAvailableFiles } from "../../api/config";
import SourceFileOptions from "./components/SourceFileOptions";
import DateOptions from "./components/DateOptions";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  resetState,
  setDefaultFromValue,
  setDefaultToValue,
  setDefaultValue,
  setIsVariableRanged,
  setSourceFiles,
} from "../../app/features/addConfigVar/addConfigVarSlice";
import { toastError, toastSuccess } from "../common/toast";

const AddConfigVar = () => {
  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const inputTypeOptions = ["text", "date", "dropdown"];
  const [variableName, setVariableName] = useState("");
  const [inputType, setInputType] = useState();

  const dispatch = useDispatch();
  const configVarState = useSelector((state) => state.addConfigVar);

  // state cleanup
  useEffect(() => () => dispatch(resetState()), [dispatch]);

  const {
    selectedSourceFile,
    selectedLabelField,
    selectedValueField,
    datePickerType,
    dateOffset,
    format,
    ranged,
    defaultValue,
    defaultFromValue,
    defaultToValue,
  } = configVarState;
  const handleSubmit = async () => {
    try {
      if (!variableName || !variableName.startsWith("$var")) {
        throw new Error("variable name should start with $var");
      }
      let payload;
      setLoader(true);
      if (inputType === "dropdown") {
        payload = {
          name: variableName,
          inputType,
          inputSourceFile: selectedSourceFile,
          labelColumn: selectedLabelField,
          valueColumn: selectedValueField,
          ranged,
        };
      } else if (inputType === "date") {
        payload = {
          name: variableName,
          inputType,
          ranged,
          datePickerType,
          dateOffset,
          format,
        };
      } else {
        payload = {
          name: variableName,
          inputType,
          ranged,
        };
      }

      if (ranged) {
        payload.defaultFromValue = defaultFromValue;
        payload.defaultToValue = defaultToValue;
      } else {
        payload.defaultValue = defaultValue;
      }

      const { data } = await addConfigVariable(payload);
      setLoader(false);
      toastSuccess(data?.message);
      console.log("data", payload);
      navigate("/configVar");
    } catch (error) {
      setLoader(false);
      return toastError(error?.message);
    }
  };

  const getAvailableFilesReqest = async () => {
    const { data: files } = await getAvailableFiles();
    dispatch(setSourceFiles(files));
  };

  const handleInputTypeChange = async (inputType) => {
    if (inputType === "dropdown") {
      try {
        setLoader(true);
        // get list of files and display them in dropdown
        await getAvailableFilesReqest();
        setLoader(false);
      } catch (error) {
        setLoader(false);
        toastError(error.message);
      }
    } else {
    }
    setInputType(inputType);
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      bgcolor="background.paper"
      sx={{ minHeight: "90vh", padding: "20px" }}
    >
      <h1 className="page-head">Add Config Variable</h1>
      <FormControl fullWidth>
        <Grid container sx={{ flexDirection: "column" }} spacing={3}>
          <Grid item>
            <TextField
              fullWidth
              type="name"
              required
              label="Variable Name"
              name="variableName"
              value={variableName}
              onChange={(e) => setVariableName(e.target.value)}
              placeholder="Enter Variable Name"
            />
          </Grid>

          {/* Input type dropdown */}

          <Grid item>
            <TextField
              fullWidth
              select
              label="Select input type"
              required
              name="inputType"
              value={inputType}
              onChange={(e) => handleInputTypeChange(e.target.value)}
            >
              {inputTypeOptions.map((inputType) => (
                <MenuItem key={inputType} value={inputType}>
                  {inputType}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {inputType === "dropdown" && <SourceFileOptions loader={loader} />}

          {inputType === "date" && <DateOptions />}

          {inputType && !ranged && (
            <Grid item>
              <TextField
                fullWidth
                type="name"
                required
                label="Default variable value"
                name="defaultValue"
                value={defaultValue}
                onChange={(e) => dispatch(setDefaultValue(e.target.value))}
                placeholder="Default variable value"
              />
            </Grid>
          )}

          {inputType && ranged && (
            <Grid item>
              <FormControl fullWidth>
                <Grid container spacing={2}>
                  <Grid item>
                    <TextField
                      fullWidth
                      type="name"
                      required
                      label="Default from value"
                      name="defaultFromValue"
                      value={defaultFromValue}
                      onChange={(e) =>
                        dispatch(setDefaultFromValue(e.target.value))
                      }
                      placeholder="Default variable value"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      type="name"
                      required
                      label="Default to value"
                      name="defaultToValue"
                      value={defaultToValue}
                      onChange={(e) =>
                        dispatch(setDefaultToValue(e.target.value))
                      }
                      placeholder="Default variable value"
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          )}

          <Grid item>
            <FormControlLabel
              label="Make variable ranged"
              value={ranged}
              onChange={(_event, val) => dispatch(setIsVariableRanged(val))}
              control={<Checkbox />}
            />
          </Grid>
          <Grid
            item
            className="submit-cont"
            style={{ justifyContent: "start", marginTop: 20 }}
          >
            <input type="submit" value="Save" />
          </Grid>
        </Grid>
      </FormControl>
      {loader && <Loader />}
    </Box>
  );
};

export default AddConfigVar;
