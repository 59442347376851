import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "../../common/muiImports";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import NoLogsFound from "./NoLogsFound";
import DumpFilePaths from "./DumpFilePaths";

const Log = () => {
  const { taskLog } = useSelector((state) => state.runStatuses);
  const [nestedDumpFilePaths, setNestedDumpFilePaths] = useState([]);

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (isJsonString(taskLog)) {
      const parsed = JSON.parse(taskLog);
      if (
        parsed?.nestedDumpFilePaths &&
        Object.entries(parsed?.nestedDumpFilePaths)?.length
      ) {
        // setNestedDumpFilePaths(parsed.nestedDumpFilePaths);
        const paths = [];
        Object.entries(parsed.nestedDumpFilePaths).forEach(
          ([objectName, dumpFilePath]) => {
            paths.push({ objectName, dumpFilePath });
          }
        );

        setNestedDumpFilePaths(paths);
      }
    }

    const cleanup = () => {
      setNestedDumpFilePaths([]);
    };

    return cleanup;
  }, [taskLog]);

  return (
    <div className="monitor-table-cont">
      <h5 className="monitor-table-head">Logs </h5>
      <div className="monitor-log-cont">
        <TableContainer
          component={Paper}
          sx={{
            minHeight: 200,
            maxHeight: 600,
            width: 450,
            overflowY: "auto",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Logs</TableCell>
              </TableRow>
            </TableHead>
            {taskLog ? (
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    {taskLog && (
                      <pre>
                        <code>
                          {isJsonString(taskLog)
                            ? JSON.stringify(JSON.parse(taskLog), null, 2)
                            : taskLog}
                        </code>
                      </pre>
                    )}
                  </TableCell>
                </TableRow>
                {!!nestedDumpFilePaths.length && (
                  <DumpFilePaths nestedDumpFilePaths={nestedDumpFilePaths} />
                )}
              </TableBody>
            ) : (
              <NoLogsFound />
            )}
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Log;
