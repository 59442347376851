import * as React from "react";
import TextField from "@mui/material/TextField";
import { Grid, IconButton, MenuItem } from "@mui/material";
import AddCircleOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlined from "@mui/icons-material/RemoveCircleOutline";
import { getAvailableFiles } from "../../../api/config";
import {
  addGsheetFileAndRangeValueRow,
  deleteGsheetFileAndRangeValueRow,
  setGSheetFileAndRangeValue,
} from "../../../app/features/taskFormSlice/taskFormSlice";
import { useDispatch, useSelector } from "react-redux";
export default function GsheetFileAndRangesFields() {
  const [files, setFiles] = React.useState([]);
  const taskFormState = useSelector((state) => state.taskForm);
  const { gSheetFileAndRangValue } = taskFormState;

  const dispatch = useDispatch();
  React.useEffect(() => {
    const getFilesInDataDir = async () => {
      const { data } = await getAvailableFiles();
      setFiles(data.map((el) => el.name));
    };

    getFilesInDataDir();
  }, []);

  const handleAddClick = () => {
    dispatch(addGsheetFileAndRangeValueRow());
  };

  const handleDeleteClick = () => {
    dispatch(deleteGsheetFileAndRangeValueRow());
  };

  const handleChange = (idx, name, value) => {
    dispatch(setGSheetFileAndRangeValue({ idx, name, value }));
  };
  return (
    <>
      {gSheetFileAndRangValue.map((el, idx) => {
        console.log("el", el);
        return (
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <TextField
                  required
                  name="Sheet"
                  label="Sheet"
                  value={el.Sheet}
                  onChange={(e) =>
                    handleChange(idx, e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  required
                  select
                  name="File"
                  label="File"
                  fullWidth
                  value={el.File}
                  onChange={(e) =>
                    handleChange(idx, e.target.name, e.target.value)
                  }
                >
                  {files.map((el) => (
                    <MenuItem value={el}>{el}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  type="number"
                  required
                  name="rowindex"
                  label="Row Index"
                  fullWidth
                  value={el.rowindex}
                  onChange={(e) =>
                    handleChange(idx, e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  type="number"
                  required
                  name="colindex"
                  label="Column Index"
                  fullWidth
                  value={el.colindex}
                  onChange={(e) =>
                    handleChange(idx, e.target.name, e.target.value)
                  }
                />
              </Grid>
              {idx === gSheetFileAndRangValue.length - 1 && (
                <Grid item sx={{ alignItems: "center", display: "flex" }}>
                  {idx !== 0 && (
                    <IconButton onClick={handleDeleteClick}>
                      <RemoveCircleOutlined />
                    </IconButton>
                  )}
                  <IconButton onClick={handleAddClick}>
                    <AddCircleOutlined />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}
