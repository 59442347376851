import { Route } from "react-router";
import AddFlow from "../components/flow/AddFlow";
import EditFlow from "../components/flow/EditFlow";
import AdminRoute from "../components/HOC/AdminRoute";
import ProtectedRoute from "../components/HOC/ProtectedRoute";
import Layout from "../components/layout/layout";
import Flow from "../pages/Flow";
import ViewDetails from "../pages/ViewDetails";

const FlowRoutes = [
  <Route
    path="/flow"
    key="/flow"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <Flow />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
  <Route
    path="/flow/:add-flow"
    key="/flow/:add-flow"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <AddFlow />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
  <Route
    path="/flow/view-flow/:id"
    key="/flow/view-flow/:id"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <ViewDetails />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
  <Route
    path="/flow/:edit-flow/:id"
    key="/flow/:edit-flow/:id"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <EditFlow />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
];

export default FlowRoutes;
