import { Typography } from "../components/common/muiImports";

const NotFound = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontSize: "80px" }}>404</Typography>
      <p>Page not found!</p>
    </div>
  );
};

export default NotFound;
