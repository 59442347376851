import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/field/loader";
import { deleteFlow, getFlow } from "../api/flows";
import ConfirmationDialog from "../components/ConfirmationDialog";
import {
  AssignmentOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { toastError, toastSuccess } from "../components/common/toast";

const Flow = () => {
  const [flow, setFlow] = useState([]);

  const [loader, setLoader] = useState(false);

  const [itemToDelete, setItemToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  useEffect(() => {
    setLoader(true);
    const fetchFlows = async () => {
      try {
        const response = await getFlow();
        setLoader(false);
        setFlow(
          response.data.reverse().map((item) => {
            return {
              ...item,
              readMore: "none",
            };
          })
        );
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };
    fetchFlows();
  }, []);

  const handleDeleteClick = async () => {
    setLoader(true);
    try {
      const result = await deleteFlow(itemToDelete._id);
      setLoader(false);
      toastSuccess(result.data.message, { autoClose: 2000 });
      setFlow(flow.filter((item) => item._id !== itemToDelete._id));
      setShowConfirmationModal(false);
      return;
    } catch (error) {
      setLoader(false);
      setShowConfirmationModal(false);
      return toastError(error?.message, { autoClose: 2000 });
    }
  };

  const handleReadMoreClick = (index) => {
    const readMoreHandle = [...flow];
    readMoreHandle[index].readMore = "block";
    setFlow(readMoreHandle);
  };

  return (
    <>
      <div>
        <h1 className="page-head">Flow </h1>
        <div className="inner-body-cont">
          <div className="btn-bloat-right">
            <Link className="commn-btn" to="/flow/add-flow">
              Create New
            </Link>
          </div>

          <div className="commn-table-cont table-responsive-md">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Desc.</th>
                  {/* <th scope="col">Tasks</th> */}
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {flow.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th className="first-row" scope="row">
                        {item.name}
                      </th>
                      <td className="second-row">
                        <p>
                          <span
                            style={{
                              display:
                                item.readMore === "none" ? "block" : "none",
                            }}
                            className="short-decp"
                          >
                            {item.description.length > 150
                              ? item.description.slice(0, 150)
                              : item.description}
                            <span
                              className="read-more-text"
                              onClick={() => handleReadMoreClick(index)}
                            >
                              {" "}
                              {item.description.length > 150
                                ? "Read More..."
                                : ""}
                            </span>
                          </span>
                          <span
                            style={{ display: item.readMore }}
                            className="full-text"
                          >
                            {item.description}
                          </span>
                        </p>
                      </td>
                      {/* <td className="third-row">
                        <p>
                          {item.tasks.map((_item, index) =>
                            index !== item.tasks.length - 1
                              ? `${_item.name}, `
                              : _item.name
                          )}
                        </p>
                      </td> */}
                      <td className="fourth-row">
                        <Link
                          to={`/flow/view-flow/${item._id}`}
                          state={{ tab: "flow", name: item.name }}
                          className="view-link"
                        >
                          <AssignmentOutlined sx={{ color: "grey" }} />
                        </Link>
                        <Link
                          to={`/flow/edit-flow/${item._id}`}
                          className="view-link"
                        >
                          <EditOutlined sx={{ color: "grey" }} />
                        </Link>
                        <span
                          className="delete-link"
                          onClick={() => {
                            setItemToDelete(item);
                            setShowConfirmationModal(true);
                          }}
                        >
                          <DeleteOutlined />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        title="Are you sure you want to delete this?"
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleDeleteClick={handleDeleteClick}
        message={`${
          itemToDelete?.name ? itemToDelete?.name : "Selected item"
        } will be deleted`}
      />
      {loader && <Loader />}
    </>
  );
};

export default Flow;
