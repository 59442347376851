import { http } from "./https";

export const getUsers = async () => {
  const response = await http.post(`users`);
  return response;
};

export const deleteUser = async ({ uid }) => {
  const response = await http.delete(`users/${uid}`);
  return response;
};

export const sendPasswordResetEmail = async (payload) => {
  const response = await http.post(`users/reset-password`, payload);
  return response;
};

export const createUser = async (payload) => {
  const response = await http.post(`users/create-user`, payload);
  return response;
};

export const getUserRole = async () => {
  const response = await http.post("users/get-user-role");
  return response;
};
