import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/field/loader";
import { deleteVariable, getVariables } from "../api/config";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { toastError, toastSuccess } from "../components/common/toast";

const ConfigVar = () => {
  const [configVars, setConfigVars] = useState([]);
  const [loader, setLoader] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    const getConfigVariables = async () => {
      setLoader(true);
      try {
        const { data } = await getVariables();
        setLoader(false);
        console.log("data", data);
        setConfigVars(data);
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };

    getConfigVariables();
  }, []);

  const handleDeleteClick = async () => {
    console.log("taskId", itemToDelete);
    try {
      const payload = {
        variableId: itemToDelete._id,
      };
      const { data } = await deleteVariable(payload);
      setConfigVars((prevState) =>
        prevState.filter((el) => el._id !== itemToDelete._id)
      );
      setShowConfirmationModal(false);
      return toastSuccess(data.message);
    } catch (error) {
      setShowConfirmationModal(false);
      return toastError(error?.message);
    }
  };

  return (
    <>
      <div>
        <h1 className="page-head">Config Variables </h1>
        <div className="inner-body-cont">
          <div className="btn-bloat-right">
            <Link className="commn-btn" to="/configVar/add-configVar">
              Create New
            </Link>
          </div>

          <div className="commn-table-cont table-responsive-md">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {configVars.map((configVar) => {
                  return (
                    <tr key={configVar._id}>
                      <th className="first-row" scope="row">
                        {configVar.name}
                      </th>
                      <td className="third-row">
                        <p>{configVar.inputType}</p>
                      </td>
                      <td className="fourth-row">
                        <Link
                          to={`/configVar/edit-configVar/${configVar._id}`}
                          className="view-link"
                        >
                          <EditOutlined sx={{ color: "grey" }} />
                        </Link>
                        <span
                          onClick={() => {
                            setItemToDelete(configVar);
                            setShowConfirmationModal(true);
                          }}
                          className="delete-link"
                        >
                          <DeleteOutlined sx={{ color: "grey" }} />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {loader && <Loader />}
      <ConfirmationDialog
        title="Are you sure you want to delete this?"
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleDeleteClick={handleDeleteClick}
        message={`${itemToDelete?.name ? itemToDelete?.name : "Selected item"} will be deleted`}
      />
    </>
  );
};

export default ConfigVar;
