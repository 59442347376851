import * as React from "react";
import TextField from "@mui/material/TextField";
import { Grid, IconButton } from "@mui/material";
import AddCircleOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlined from "@mui/icons-material/RemoveCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import {
  addGsheetRangeAndValueRow,
  deleteGsheetRangeAndValueRow,
  setGsheetRangeAndValue,
} from "../../../app/features/taskFormSlice/taskFormSlice";
/**
 * @param {{gSheetRangeAndValue: any[]}} props
 * @returns
 */
export default function GsheetRangeAndValuesFields() {
  const taskFormState = useSelector((state) => state.taskForm);
  const dispatch = useDispatch();
  const { gSheetRangeAndValue } = taskFormState;
  const handleAddClick = () => {
    dispatch(addGsheetRangeAndValueRow());
  };

  const handleDeleteClick = () => {
    dispatch(deleteGsheetRangeAndValueRow());
  };

  const handleChange = (idx, name, value) => {
    dispatch(setGsheetRangeAndValue({ idx, name, value }));
  };
  return (
    <>
      {gSheetRangeAndValue.map((el, idx) => {
        return (
          <Grid item style={{ display: "flex" }} sx={12}>
            <Grid container spacing={1}>
              <Grid item>
                <TextField
                  required
                  name="range"
                  label="range"
                  value={el.range}
                  onChange={(e) =>
                    handleChange(idx, e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  name="value"
                  label="value"
                  fullWidth
                  value={el.value}
                  onChange={(e) =>
                    handleChange(idx, e.target.name, e.target.value)
                  }
                />
              </Grid>
              {idx === gSheetRangeAndValue.length - 1 && (
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  {idx !== 0 && (
                    <IconButton onClick={handleDeleteClick}>
                      <RemoveCircleOutlined />
                    </IconButton>
                  )}
                  <IconButton onClick={handleAddClick}>
                    <AddCircleOutlined />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}
