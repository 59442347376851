import { http } from "./https";

export const getFlow = async () => {
  const response = await http.get(`/flow/all`);
  return response;
};

export const executeFlow = async (flowId, payload) => {
  const response = await http.post(`/flow/execute-flow/${flowId}`, payload);
  return response;
};

export const addFlow = async (payload) => {
  const response = await http.post("/flow/add-flow", payload);
  return response;
};

export const getFlowById = async (flowId) => {
  const response = await http.get(`/flow/${flowId}`);
  return response;
};

export const editFlow = async (flowId, payload) => {
  const response = await http.post(`/flow/edit-flow/${flowId}`, payload);
  return response;
};

export const deleteFlow = async (flowId) => {
  const response = await http.delete(`/flow/${flowId}`);
  return response;
};

export const recordsProcessedWithinDuration = async (startDate, endDate) => {
  const response = await http.post("/task-status/records-processed", {
    startDate, endDate
  })

  return response;
}