import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../field/loader";
import { editFlow, getFlowById } from "../../api/flows";
import { getTask } from "../../api/task";
import { getVariables } from "../../api/config";
import { toastError, toastSuccess } from "../common/toast";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { Cancel } from "@mui/icons-material";
import DraggableList from "./DraggableList";
import { reorder } from "../../utils/helpers";

const useStyles = makeStyles({
  flexPaper: {
    flex: 1,
    margin: 16,
    minWidth: 350,
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
});

const EditFlow = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [flowDetails, setFlowDetails] = useState(null);
  const [loader, setLoader] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [configVariables, setConfigVariables] = useState([]);
  const [reorderTasks, setReorderTasks] = useState(false);

  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectedConfigVars, setSelectedConfigVars] = useState([]);

  const [taskLabelMapping, setTaskLabelMapping] = useState({});
  const [configVarLabelMapping, setConfigVarLabelMapping] = useState({});

  const params = useParams();
  const { id } = params;

  useEffect(() => {
    setLoader(true);
    const getFlow = async () => {
      try {
        const { data: flow } = await getFlowById(id);
        const { data: tasks } = await getTask();
        const { data: configVars } = await getVariables();
        setLoader(false);

        setFlowDetails(flow);

        setTasks(tasks);

        // store a map for task label -> task id
        const taskIdToLabelMap = {};

        for (const task of tasks) {
          taskIdToLabelMap[task._id] = task.name;
        }

        setTaskLabelMapping(taskIdToLabelMap);

        setConfigVariables(configVars);

        // store a map for configvar label -> configvar id
        const configVarIdToLabelMap = {};

        for (const configVar of configVars) {
          configVarIdToLabelMap[configVar._id] = configVar.name;
        }

        setConfigVarLabelMapping(configVarIdToLabelMap);

        setSelectedConfigVars(
          flow.configVars.map((configVar) => configVar._id)
        );

        setSelectedTasks(flow.tasks.map((task) => task._id));
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };

    getFlow();
  }, [id]);

  const onSubmitHandler = async () => {
    setLoader(true);
    const payload = {
      flowId: flowDetails._id,
      name: flowDetails?.name,
      description: flowDetails?.description,
      tasks: selectedTasks,
      configVars: selectedConfigVars,
    };

    try {
      const result = await editFlow(id, payload);
      setLoader(false);
      navigate("/flow");
      return toastSuccess(result.data.message);
    } catch (error) {
      setLoader(false);
      return toastError(error?.message);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value, name },
    } = event;

    console.log("got", value);
    if (name === "tasks") {
      setSelectedTasks(value);
    } else if (name === "configVars") {
      setSelectedConfigVars(value);
    }
  };

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(selectedTasks, source.index, destination.index);

    setSelectedTasks(newItems);
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitHandler(e);
      }}
      bgcolor="background.paper"
      sx={{ minHeight: "90vh", padding: "20px" }}
    >
      <h1 className="page-head">Edit Flow</h1>
      <FormControl fullWidth>
        <Grid container sx={{ flexDirection: "column" }} spacing={3}>
          <Grid item>
            <TextField
              fullWidth
              label="Flow Name"
              required
              value={flowDetails?.name}
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: "normal" }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label="Description"
              required
              value={flowDetails?.description}
              InputLabelProps={{ shrink: "normal" }}
              onChange={(e) =>
                setFlowDetails({
                  ...flowDetails,
                  description: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item>
            <TextField
              name="tasks"
              label="Tasks"
              select
              required
              fullWidth
              placeholder="Please Select"
              SelectProps={{
                multiple: true,
                renderValue: (selected) => {
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={taskLabelMapping[value]}
                          onDelete={() => {}}
                          deleteIcon={
                            <div
                              onMouseDown={(e) => {
                                e.stopPropagation();
                                setSelectedTasks((prevState) =>
                                  prevState.filter((taskId) => taskId !== value)
                                );
                              }}
                            >
                              <Cancel />
                            </div>
                          }
                        />
                      ))}
                    </Box>
                  );
                },
              }}
              value={selectedTasks}
              onChange={handleChange}
            >
              {tasks.map((task) => (
                <MenuItem value={task._id} key={task._id}>
                  {task.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {!!selectedTasks.length && (
            <Grid item>
              <Button
                onClick={() => setReorderTasks((prevState) => !prevState)}
              >
                Re-order?
              </Button>
            </Grid>
          )}

          {reorderTasks && (
            <Grid item>
              <div className={classes.root}>
                <Paper className={classes.flexPaper}>
                  <DraggableList
                    items={selectedTasks.map((el) => ({
                      label: taskLabelMapping[el],
                      value: el,
                    }))}
                    onDragEnd={onDragEnd}
                  />
                </Paper>
              </div>
            </Grid>
          )}

          <Grid item>
            <TextField
              name="configVars"
              label="Select variables"
              select
              fullWidth
              placeholder="Please Select"
              multiple
              value={selectedConfigVars}
              onChange={handleChange}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={configVarLabelMapping[value]}
                        onDelete={() => {}}
                        deleteIcon={
                          <div
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              setSelectedConfigVars((prevState) =>
                                prevState.filter(
                                  (configVarId) => configVarId !== value
                                )
                              );
                            }}
                          >
                            <Cancel />
                          </div>
                        }
                      />
                    ))}
                  </Box>
                ),
              }}
            >
              {configVariables.map((configVariable) => (
                <MenuItem value={configVariable._id} key={configVariable._id}>
                  {configVariable.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid
            item
            className="submit-cont"
            style={{ justifyContent: "start", marginTop: 20 }}
          >
            <input type="submit" value="Save" />
          </Grid>
        </Grid>
      </FormControl>
      {loader && <Loader />}
    </Box>
  );
};

export default EditFlow;
