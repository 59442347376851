import { Route } from "react-router";
import AdminRoute from "../components/HOC/AdminRoute";
import ProtectedRoute from "../components/HOC/ProtectedRoute";
import Layout from "../components/layout/layout";
import AddSchedule from "../components/schedule/AddSchedule";
import EditSchedule from "../components/schedule/EditSchedule";
import Schedule from "../pages/Schedule";
import ViewDetails from "../pages/ViewDetails";

const ScheduleRoutes = [
  <Route
    path="/schedule"
    key="/schedule"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <Schedule />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
  <Route
    path="/schedule/:add-schedule"
    key="/schedule/:add-schedule"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <AddSchedule />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
  <Route
    path="/schedule/view-schedule/:id"
    key="/schedule/view-schedule/:id"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <ViewDetails />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
  <Route
    path="/schedule/:edit-schedule/:id"
    key="/schedule/:edit-schedule/:id"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <EditSchedule />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
];

export default ScheduleRoutes;
