import { Grid, MenuItem, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setDatePickerType,
  setFormat,
  setOffsetType,
} from "../../../app/features/addConfigVar/addConfigVarSlice";

const DateOptions = () => {
  const datePickerOptions = [
    { label: "Date Picker", value: "date" },
    { label: "Month Picker", value: "month" },
    { label: "Year Picker", value: "year" },
  ];
  const offsetOptions = [
    { label: "Current Date", value: "current" },
    { label: "Start", value: "start" },
    { label: "End", value: "end" },
  ];
  const formatOptions = [
    "MM/DD/YYYY",
    "DD/MM/YYYY",
    "YYYY/MM/DD",
    "MMM YYYY",
    "YYYYMMDD",
  ];

  const dispatch = useDispatch();

  return (
    <>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              select
              required
              label="Select Datepicker Type"
              name="inputSource"
              onChange={(e) => dispatch(setDatePickerType(e.target.value))}
            >
              {datePickerOptions.map((inputSource) => (
                <MenuItem key={inputSource.value} value={inputSource.value}>
                  {inputSource.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <TextField
              select
              required
              fullWidth
              label="Select Offset"
              name="offsetOption"
              onChange={(e) => dispatch(setOffsetType(e.target.value))}
            >
              {offsetOptions.map((offsetOption) => (
                <MenuItem key={offsetOption.value} value={offsetOption.value}>
                  {offsetOption.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              select
              required
              name="format"
              label="Select Format"
              onChange={(e) => dispatch(setFormat(e.target.value))}
            >
              {formatOptions.map((inputSource) => (
                <MenuItem key={inputSource} value={inputSource}>
                  {inputSource}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DateOptions;
