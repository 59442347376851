import { useEffect, useState } from "react";
import { getAllGoogleSheets } from "../../../api/google-sheets";
import {
  Fade,
  Backdrop,
  Modal,
  Grid,
  Box,
  Typography,
  CloseIcon,
  DataGrid,
} from "../../common/muiImports";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  height: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 1,
};

const GoogleSheetsListModal = ({ open, handleClose }) => {
  const [gSheets, setGsheets] = useState([]);

  useEffect(() => {
    const fetchSheets = async () => {
      const { data } = await getAllGoogleSheets();
      setGsheets(data);
    };

    open && fetchSheets();
  }, [open]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <CloseIcon
              onClick={handleClose}
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Google sheets
                  </Typography>
                </Box>
                <div style={{ height: 250, width: "100%" }}>
                  <DataGrid
                    sx={{ marginTop: "20px" }}
                    onCellClick={(data) => console.log("clicked", data)}
                    hideFooter={true}
                    columns={[
                      {
                        field: "filePrefix",
                        headerName: "File Prefix",
                        width: 420,
                        align: "left",
                      },
                      {
                        field: "spreadsheetId",
                        headerName: "Link",
                        width: 250,
                        renderCell: (cell) => {
                          console.log("cle", cell);
                          return (
                            <>
                              <a
                                href={`https://docs.google.com/spreadsheets/d/${cell.value}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Link to sheet
                              </a>
                            </>
                          );
                        },
                      },
                    ]}
                    rows={gSheets.map((el) => ({ ...el, id: el.filePrefix }))}
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default GoogleSheetsListModal;
