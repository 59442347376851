import React from "react";

const ViewFlow = ({ detail }) => {
  const { name, description, tasks, configVars } = detail;
  let variables = "";
  if (configVars) {
    for (const configVar of configVars) {
      variables += configVar.name + ",";
    }
  }
  return (
    <>
      <tr>
        <th scope="col">Name: </th>
        <td>{name}</td>
      </tr>

      <tr>
        <th scope="col">Description</th>
        <td>{description}</td>
      </tr>
      <tr>
        <th scope="col">Variable Sel: </th>
        <td>{`${variables}`}</td>
      </tr>
      {tasks && (
        <tr>
          <th scope="col">Tasks: </th>
          <td>
            {tasks.map((task, idx) => {
              if (idx !== tasks.length - 1) return task.name + ", ";
              else return task.name;
            })}
          </td>
        </tr>
      )}
    </>
  );
};

export default ViewFlow;
