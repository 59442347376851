import { useState } from "react";
import { uploadFile } from "../../../api/others";
import {
  Fade,
  Backdrop,
  Modal,
  Grid,
  Box,
  Typography,
  CloseIcon,
  Button,
  TextField,
} from "../../common/muiImports";
import { toastError, toastSuccess } from "../../common/toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  height: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 1,
};

const UploadFileModal = ({ open, handleClose }) => {
  const [file, setFile] = useState();

  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("file", file);
      await uploadFile(formData);
      toastSuccess("File uploaded successfully");
    } catch (err) {
      toastError("Upload failed");
    }
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => {
          setFile(null);
          handleClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <CloseIcon
              onClick={handleClose}
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Upload file
                </Typography>
                <Box component="form" onSubmit={handleUpload}>
                  <div
                    style={{
                      height: 250,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      // alignItems: "center",
                    }}
                  >
                    <TextField
                      label="Upload file"
                      type="file"
                      onChange={(e) => setFile(e.target.files[0])}
                      fullWidth
                      InputLabelProps={{ shrink: "normal" }}
                    />
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ marginTop: 2 }}
                      disabled={!file}
                    >
                      Upload
                    </Button>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default UploadFileModal;
