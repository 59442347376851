import { Navigate } from "react-router";
import { useAuth } from "../../hooks/useAuth";
import Loader from '../field/loader'

const ProtectedRoute = ({ children }) => {
  const user = useAuth();

  return typeof user === "undefined" ? (
    <Loader />
  ) : user ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
