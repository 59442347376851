import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getTaskStatus } from "../../../api/tasksDetails";
import {
  setRunStatuses,
  setSelectedRunStatus,
  setSelectedTaskStatus,
  setTaskStatuses,
} from "../../../app/features/runStatus/runStatusSlice";
import { getRunStatus } from "../../../api/runStatus";

const columns = [
  { field: "flowName", headerName: "Flow Name", width: 230 },
  { field: "status", headerName: "Status", width: 230 },
  { field: "startTime", headerName: "Start Time", width: 230 },
  { field: "endTime", headerName: "End Time", width: 230 },
  { field: "ranBy", headerName: "Ran By", width: 230 },
];

const RunStatus = () => {
  const { runStatuses, selectedRunStatus, fromDate, toDate } = useSelector(
    (state) => state.runStatuses
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const getAllStatuses = async () => {
      // get all runStatuses
      const { data: runStatuses } = await getRunStatus({
        from: fromDate,
        to: toDate,
      });

      // set first runStatus as selected by default
      dispatch(setRunStatuses(runStatuses));

      // if there are no runStatuses set taskStatuses to empty array
      if (!runStatuses.length) {
        dispatch(setTaskStatuses([]))
        dispatch(setSelectedTaskStatus(null))
        return;
      }

      // get all taskStatuses for selected runStatus
      const { data: taskStatuses } = await getTaskStatus(runStatuses[0]._id);

      dispatch(setTaskStatuses(taskStatuses));
    };
    getAllStatuses();
  }, [dispatch, fromDate, toDate]);

  const handleClick = async ({ row }) => {
    dispatch(setSelectedRunStatus(row.id));
    const { data: taskStatuses } = await getTaskStatus(row.id);
    dispatch(setTaskStatuses(taskStatuses));
  };

  return (
    <>
      <div className="monitor-table-cont">
        <h5 className="monitor-table-head">Recent Run status </h5>
        <div style={{ height: 400, width: "100%" }}>
          {runStatuses && (
            <DataGrid
              rows={runStatuses.map((el) => {
                return {
                  ...el,
                  startTime: el.startTime
                    ? new Date(el.startTime).toLocaleString()
                    : "N/A",
                  endTime: el.endTime
                    ? new Date(el.endTime).toLocaleString()
                    : "N/A",
                  ranBy: el.ranBy?.name || el?.ranBy?.email,
                  id: el._id.toString(),
                };
              })}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              onCellClick={handleClick}
              selectionModel={selectedRunStatus ? selectedRunStatus._id : ""}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default RunStatus;
