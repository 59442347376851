import {
  Fade,
  Backdrop,
  Modal,
  Grid,
  Box,
  Typography,
  Button,
  CloseIcon,
  TextField,
  MenuItem,
  Divider,
  ListItemText,
} from "../../common/muiImports";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useEffect, useState } from "react";
import ConfigVarInput from "./ConfigVarInput";
import { executeFlow } from "../../../api/flows";
import { toastError, toastSuccess } from "../../common/toast";
import SaveFlowVariantForm from "./SaveFlowVariantForm";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedFlowVariant,
  setVariants,
} from "../../../app/features/flow/flowSlice";
import { makeStyles } from "@material-ui/core/styles";
import { getVariantsByFlowId } from "../../../api/flowVariant";
import { ListItemAvatar, ListItemButton } from "@mui/material";
import { AssignmentOutlined } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 1,
  maxHeight: "90vh",
  overflowY: "auto",
};

const FlowExecuteModal = ({ showExecuteModal, setShowExecuteModal }) => {
  const { selectedFlow, configVarValues, variants } = useSelector(
    (state) => state.flows
  );

  const { name, _id: flowId, tasks, configVars } = selectedFlow;
  const [showFlowVariantForm, setShowFlowVariantForm] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getVariants = async () => {
      const { data } = await getVariantsByFlowId(flowId);
      console.log("data", data);
      dispatch(setVariants(data));
    };

    getVariants();
  }, [dispatch, flowId]);

  const handleClose = () => setShowExecuteModal(false);
  const handleExecuteClick = async (id) => {
    try {
      const payload = {
        configVarValues,
      };

      // check if values are provided for selected config variables
      const { data } = await executeFlow(id, payload);
      data.success && toastSuccess(data.message);
      setShowExecuteModal(false);
    } catch (err) {
      console.log("FLOWERROR", err);
      toastError(err.message);
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      "&::-webkit-scrollbar": {
        width: `8px`,
        backgroundColor: `#F5F5F5`,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
        backgroundColor: `#ffff`,
        borderRadius: `8px`,
      },

      "&::-webkit-scrollbar-thumb": {
        backgroundImage: `-webkit-linear-gradient(90deg,
      rgb(255, 255, 255) 0%,
      rgb(212, 212, 212) 25%,
      transparent 100%,
      rgb(194, 194, 194) 75%,
      transparent)`,
        borderRadius: `10px`,
      },
    },
  }));
  const classes = useStyles();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showExecuteModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showExecuteModal}>
          <Box sx={style}>
            <CloseIcon
              onClick={handleClose}
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            />
            <Grid
              container
              spacing={2}
              sx={{ overflowY: "auto", padding: "20px" }}
              className={classes.root}
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {name}
                  </Typography>
                </Box>
                {variants && !!variants?.length && (
                  <Grid item mt={2}>
                    <TextField
                      select
                      label="Select variant"
                      fullWidth
                      onChange={(e) => {
                        dispatch(setSelectedFlowVariant(e.target.value));
                      }}
                    >
                      {variants.map((variant) => (
                        <MenuItem key={variant._id} value={variant._id}>
                          {variant.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}

                {!!configVars?.length && (
                  <div>
                    <Divider sx={{ marginTop: "10px" }} />
                    <Typography
                      id="transition-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{ mt: 3 }}
                    >
                      Config Variables
                    </Typography>
                    {configVars?.map((configVar) => (
                      <Box
                        key={configVar._id}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: 4,
                        }}
                      >
                        <Typography sx={{ mr: 1 }}>{configVar.name}</Typography>
                        <ConfigVarInput configVar={configVar} />
                      </Box>
                    ))}
                  </div>
                )}

                <Divider sx={{ marginTop: "10px" }} />
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ mt: 3 }}
                >
                  Tasks
                </Typography>
                <List>
                  {tasks &&
                    tasks.map((task) => (
                      <ListItem key={task._id}>
                        <ListItemButton>
                          <ListItemAvatar>
                            <AssignmentOutlined />
                          </ListItemAvatar>
                          <ListItemText>
                            <Typography key={task._id}>{task.name}</Typography>
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
                <Grid
                  item
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  {!!configVars.length && (
                    <Typography
                      id="transition-modal-description"
                      sx={{ mt: 2 }}
                    >
                      <Button
                        onClick={() =>
                          setShowFlowVariantForm((prevState) => !prevState)
                        }
                      >
                        Save this variant?
                      </Button>
                    </Typography>
                  )}
                  <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => handleExecuteClick(flowId)}
                    >
                      Execute
                    </Button>
                  </Typography>
                </Grid>
                {showFlowVariantForm && (
                  <SaveFlowVariantForm handleClose={handleClose} />
                )}
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default FlowExecuteModal;
