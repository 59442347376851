import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/field/loader";
import { DeleteOutlined, LockResetOutlined } from "@mui/icons-material";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { toastError, toastSuccess } from "../components/common/toast";
import { deleteUser, getUsers, sendPasswordResetEmail } from "../api/admin";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loader, setLoader] = useState(false);

  const [itemToDelete, setItemToDelete] = useState(null);
  const [userToSendResetPasswordLinkTo, setUserToSendResetPasswordLinkTo] =
    useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showResetPasswordConfirmation, setShowResetPasswordConfirmation] =
    useState(false);
  useEffect(() => {
    setLoader(true);
    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        setLoader(false);
        setUsers(response.data);
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };
    fetchUsers();
  }, []);

  const handleDeleteClick = async () => {
    setLoader(true);
    const payload = {
      uid: itemToDelete.uid,
    };
    try {
      const result = await deleteUser(payload);
      setLoader(false);
      toastSuccess(result.data.message, { autoClose: 2000 });
      setShowConfirmationModal(false);
      setItemToDelete(null);
      setUsers(users.filter((user) => user.uid !== itemToDelete.uid));
      return;
    } catch (error) {
      setLoader(false);
      return toastError(error?.message, { autoClose: 2000 });
    }
  };

  const handleSendResetPasswordEmail = async () => {
    setLoader(true);
    const payload = {
      email: userToSendResetPasswordLinkTo.email,
    };
    try {
      const result = await sendPasswordResetEmail(payload);
      setLoader(false);
      toastSuccess(result.data.message, { autoClose: 2000 });
      setShowResetPasswordConfirmation(false);
      setUserToSendResetPasswordLinkTo(null);
    } catch (error) {
      setLoader(false);
      return toastError(error?.message, { autoClose: 2000 });
    }
  };

  return (
    <>
      <div>
        <h1 className="page-head">Users </h1>
        <div className="inner-body-cont">
          <div className="btn-bloat-right">
            <Link className="commn-btn" to="/users/register-user">
              Create New
            </Link>
          </div>

          <div className="commn-table-cont table-responsive-md">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Role</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {users.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th className="first-row" scope="row">
                        {item.displayName}
                      </th>
                      <td className="second-row">
                        <p>
                          <span
                            style={{
                              display:
                                item.readMore === "none" ? "block" : "none",
                            }}
                            className="short-decp"
                          >
                            {item.description?.length > 150
                              ? item.description.slice(0, 150)
                              : item.description}
                          </span>
                          <span
                            style={{ display: item.readMore }}
                            className="full-text"
                          >
                            {item.email}
                          </span>
                        </p>
                      </td>
                      <td className="third-row">
                        <p>{item.admin ? "Admin" : "User"}</p>
                      </td>
                      <td className="fourth-row">
                        <span
                          onClick={() => {
                            setItemToDelete(item);
                            setShowConfirmationModal(true);
                          }}
                          className="delete-link"
                        >
                          <DeleteOutlined />
                        </span>

                        <span>
                          <LockResetOutlined
                            onClick={() => {
                              setUserToSendResetPasswordLinkTo(item);
                              setShowResetPasswordConfirmation(true);
                            }}
                            sx={{ color: "grey", ml: 3, cursor: "pointer" }}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        title="Are you sure you want to delete this?"
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleDeleteClick={handleDeleteClick}
        message={`${
          itemToDelete?.displayName
            ? itemToDelete?.displayName
            : "Selected item"
        } will be deleted`}
      />

      <ConfirmationDialog
        title="Send reset password email?"
        open={showResetPasswordConfirmation}
        setOpen={setShowResetPasswordConfirmation}
        handleClose={() => setShowResetPasswordConfirmation(false)}
        handleDeleteClick={handleSendResetPasswordEmail}
        message={`Send reset password link to ${userToSendResetPasswordLinkTo?.email}?`}
        confirmButtonText="Send"
      />
      {loader && <Loader />}
    </>
  );
};

export default Users;
