import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../field/loader";
import { getTask } from "../../api/task";
import { addFlow } from "../../api/flows";
import { getVariables } from "../../api/config";
import { toastError, toastSuccess } from "../common/toast";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
export const AddFlow = () => {
  let navigate = useNavigate();
  const [flowName, setFlowName] = useState("");
  const [flowDescription, setFlowDescription] = useState("");
  const [tasks, setTasks] = useState([]);
  const [loader, setLoader] = useState(false);
  const [configVariables, setConfigVariables] = useState([]);
  const [selectedConfigVars, setSelectedConfigVars] = useState([]);

  const [selectedTasks, setSelectedTasks] = useState([]);

  useEffect(() => {
    setLoader(true);
    const fetchTaskAndVariables = async () => {
      try {
        const { data: tasks } = await getTask();
        const { data: variables } = await getVariables();
        const configVars = variables.map((configVar) => {
          return { label: configVar.name, value: configVar._id };
        });
        setConfigVariables(configVars);
        setLoader(false);
        const selectValue = tasks?.map((task) => {
          return {
            label: task.name,
            value: task._id,
          };
        });
        setTasks(selectValue);
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };

    fetchTaskAndVariables();
  }, []);

  const onSubmitHandler = async () => {
    const payload = {
      name: flowName,
      description: flowDescription,
      configVars: selectedConfigVars.map((configVar) => configVar.value),
      tasks: selectedTasks.map((task) => task.value),
    };
    setLoader(true);
    try {
      const result = await addFlow(payload);
      setLoader(false);
      navigate("/flow");
      return toastSuccess(result.data.message);
    } catch (error) {
      setLoader(false);
      const errorText =
        error?.response?.data?.message?.toString() || error?.message;
      console.log("error", errorText);
      return toastError(errorText);
    }
  };
  const handleChange = (event) => {
    const {
      target: { value, name },
    } = event;

    console.log(value);
    if (name === "tasks") {
      setSelectedTasks(value);
    } else if (name === "configVars") {
      setSelectedConfigVars(value);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitHandler(e);
      }}
      bgcolor="background.paper"
      sx={{ minHeight: "90vh", padding: "20px" }}
    >
      <h1 className="page-head">Add Flow</h1>
      <FormControl fullWidth>
        <Grid container sx={{ flexDirection: "column" }} spacing={3}>
          <Grid item>
            <TextField
              fullWidth
              type="name"
              required
              name="name"
              placeholder="Enter Flow Name"
              label="Flow Name"
              value={flowName}
              onChange={(e) => setFlowName(e.target.value)}
            />
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              multiline
              required
              label="Description"
              name="description"
              placeholder="Description here.."
              onChange={(e) => setFlowDescription(e.target.value)}
            />
          </Grid>

          <Grid item>
            <TextField
              name="tasks"
              label="Tasks"
              select
              required
              fullWidth
              placeholder="Please Select"
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value.value}
                        label={value.label}
                        onDelete={() => {}}
                        deleteIcon={
                          <div
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              setSelectedTasks((prevState) =>
                                prevState.filter(
                                  (task) => task.value !== value.value
                                )
                              );
                            }}
                          >
                            <Cancel />
                          </div>
                        }
                      />
                    ))}
                  </Box>
                ),
              }}
              value={selectedTasks}
              onChange={handleChange}
            >
              {tasks.map((task) => (
                <MenuItem value={task}>{task.label}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item>
            <TextField
              name="configVars"
              label="Select variables"
              select
              fullWidth
              placeholder="Please Select"
              multiple
              value={selectedConfigVars}
              onChange={handleChange}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map(({ value, label }) => (
                      <Chip
                        key={value}
                        label={label}
                        onDelete={() => {}}
                        deleteIcon={
                          <div
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              setSelectedConfigVars((prevState) =>
                                prevState.filter(
                                  (configVar) => configVar.value !== value
                                )
                              );
                            }}
                          >
                            <Cancel />
                          </div>
                        }
                      />
                    ))}
                  </Box>
                ),
              }}
            >
              {configVariables.map((configVariable) => (
                <MenuItem value={configVariable}>
                  {configVariable.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid
            item
            className="submit-cont"
            style={{ justifyContent: "start", marginTop: 20 }}
          >
            <input type="submit" value="Save" />
          </Grid>
        </Grid>

        {loader && <Loader />}
      </FormControl>
    </Box>
  );
};
export default AddFlow;
