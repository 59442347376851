import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  addConnection,
  getConnectionType,
  getConnectionTypeById,
} from "../../api/connections";
import Loader from "../field/loader";
import { toastError, toastSuccess } from "../common/toast";
import { Box, FormControl, Grid, MenuItem, TextField } from "@mui/material";

export const AddConnection = () => {
  let navigate = useNavigate();
  const [connectionType, setConnectionType] = useState([]);
  const [connectionTypeAttributes, setConnectionTypeAttributes] = useState([]);
  const [selectValue, setSelectValue] = useState("");
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [connectionAttribValues, setConnectionAttribValues] = useState({});

  useEffect(() => {
    const getConnectionTypes = async () => {
      const response = await getConnectionType();
      setConnectionType(response.data);
    };
    getConnectionTypes();
  }, []);

  const handleConnectionTypeChange = async (e) => {
    setSelectValue(e.target.value);
    setLoader(true);
    try {
      const result = await getConnectionTypeById(e.target.value);
      setConnectionTypeAttributes(result.data[0].attributes);
      setLoader(false);
      return toastSuccess(result.data.message, { autoClose: 2000 });
    } catch (error) {
      setLoader(false);
      return toastError(error?.message, { autoClose: 2000 });
    }
  };

  const onSubmitHandler = async () => {
    const ALPHANUMERIC_REGEX = /^[a-z0-9]+$/i;
    if (!ALPHANUMERIC_REGEX.test(name)) {
      return toastError("Connection name can only be an alphanumeric value.", {
        autoClose: 2000,
      });
    }
    setLoader(true);
    const payload = {
      name,
      description,
      connectionTypeAttributes: connectionAttribValues,
      connectionTypeId: selectValue,
    };

    try {
      const result = await addConnection(payload);
      navigate("/connection");
      setLoader(false);
      return toastSuccess(result.data.message, { autoClose: 2000 });
    } catch (error) {
      setLoader(false);
      return toastError(error?.message, { autoClose: 2000 });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitHandler(e);
      }}
      bgcolor="background.paper"
      sx={{ minHeight: "90vh", padding: "20px" }}
    >
      <h1 className="page-head">Add Connection</h1>
      <FormControl fullWidth>
        <Grid container sx={{ flexDirection: "column" }} spacing={3}>
          <Grid item>
            <TextField
              fullWidth
              label="Connection Name"
              type="name"
              required
              name="name"
              placeholder="Enter Connection Name"
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>

          <Grid item>
            <TextField
              multiline
              label="Description"
              fullWidth
              required
              name="description"
              placeholder="Description here.."
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>

          <Grid item>
            <TextField
              select
              label="Select Connection Type"
              required
              fullWidth
              onChange={handleConnectionTypeChange}
              value={selectValue}
            >
              {connectionType.map((item) => (
                <MenuItem
                  value={item.connectionTypeId}
                  key={item.connectionTypeId}
                >
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {connectionTypeAttributes.map((item) => {
            return (
              <Grid item>
                <TextField
                  fullWidth
                  label={item.label || item.name}
                  autoComplete="new-password"
                  type={item.inputField}
                  multiline={item.inputField === "textarea"}
                  name={item.name}
                  required={item.fieldRequired}
                  placeholder={`Enter Attribute value`}
                  onChange={({ target }) => {
                    const { name, value } = target;
                    const obj = {};
                    obj[name] = value;

                    setConnectionAttribValues((prevState) => ({
                      ...prevState,
                      ...obj,
                    }));
                  }}
                />
              </Grid>
            );
          })}
          <Grid
            item
            className="submit-cont"
            style={{ justifyContent: "start", marginTop: 20 }}
          >
            <input type="submit" value="Save" />
          </Grid>
        </Grid>
      </FormControl>
      {loader && <Loader />}
    </Box>
  );
};
export default AddConnection;
