import { Box, CircularProgress } from "@mui/material";
import React, { PureComponent } from "react";

export default class Loader extends PureComponent {
  render() {
    return (
      <div className="spinner">
        <Box sx={{ display: "flex" }}>
          <CircularProgress sx={{ color: "white" }} />
        </Box>
      </div>
    );
  }
}

Loader.displayName = "Loader";
