import { configureStore } from "@reduxjs/toolkit";
import flowReducer from "./features/flow/flowSlice";
import runStatusReducer from "./features/runStatus/runStatusSlice";
import addConfigVarReducer from "./features/addConfigVar/addConfigVarSlice";
import taskFormReducer from "./features/taskFormSlice/taskFormSlice";
import taskReducer from "./features/task/taskSlice";
//todo use single task slice to handle task state
export const store = configureStore({
  reducer: {
    flows: flowReducer,
    runStatuses: runStatusReducer,
    addConfigVar: addConfigVarReducer,
    taskForm: taskFormReducer,
    task: taskReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
