import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../field/loader";
import { getFlow } from "../../api/flows";
import { addSchedule } from "../../api/schedule";
import { toastError, toastSuccess } from "../common/toast";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { Cancel, InfoRounded } from "@mui/icons-material";
import { getVariantsByFlowId } from "../../api/flowVariant";
import { timezones } from "../../utils/constants";
export const AddSchedule = () => {
  let navigate = useNavigate();
  const [flows, setFlows] = useState([]);
  const [loader, setLoader] = useState(false);

  const [scheduleName, setScheduleName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [cronPattern, setCronPattern] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [successEmail, setSuccessEmail] = useState("");
  const [flowVariants, setFlowVariants] = useState([]);
  const [selectedFlowVariants, setSelectedFlowVariants] = useState([]);

  useEffect(() => {
    setLoader(true);
    const fetchFlows = async () => {
      try {
        const { data } = await getFlow();
        setLoader(false);
        setFlows(data);
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };
    fetchFlows();
  }, []);

  const handleFlowChange = async (e) => {
    try {
      setSelectedFlow(e.target.value);
      const { data } = await getVariantsByFlowId(e.target.value);
      setFlowVariants(data);
      setSelectedFlowVariants([]);
    } catch (err) {
      toastError(err.message);
    }
  };

  const onSubmitHandler = async () => {
    setLoader(true);
    const selectedFlowVariantIds = selectedFlowVariants.map((el) => el._id);
    try {
      const payload = {
        name: scheduleName,
        description,
        flow: selectedFlow,
        cronPattern,
        errorEmail,
        successEmail,
        flowVariants: selectedFlowVariantIds,
        timezone: selectedTimezone,
      };
      const result = await addSchedule(payload);
      setLoader(false);
      navigate("/schedule");
      return toastSuccess(result.data.message, { autoClose: 2000 });
    } catch (error) {
      setLoader(false);
      return toastError(error?.message, { autoClose: 2000 });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitHandler(e);
      }}
      bgcolor="background.paper"
      sx={{ minHeight: "90vh", padding: "20px" }}
    >
      <h1 className="page-head">Add Schedule</h1>
      <FormControl fullWidth>
        <Grid container sx={{ flexDirection: "column" }} spacing={3}>
          <Grid item>
            <TextField
              label="Schedule Name"
              value={scheduleName}
              onChange={(e) => setScheduleName(e.target.value)}
              fullWidth
              type="name"
              required
              placeholder="Enter Schedule Name"
            />
          </Grid>
          <Grid item>
            <TextField
              multiline
              fullWidth
              required
              label="Description"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description here.."
            />
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              label="Select flow"
              onChange={handleFlowChange}
              select
              required
              value={selectedFlow}
            >
              {flows.map((item) => (
                <MenuItem value={item._id}>{item.name}</MenuItem>
              ))}
            </TextField>
          </Grid>

          {!!flowVariants.length && (
            <Grid item>
              <TextField
                name="flowVariants"
                label="Select flow variants"
                select
                required
                fullWidth
                placeholder="Please Select"
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => {
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          return (
                            <Chip
                              key={value._id}
                              label={value.name}
                              onDelete={() => {}}
                              deleteIcon={
                                <div
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    setSelectedFlowVariants((prevState) =>
                                      prevState.filter(
                                        (flowVariant) =>
                                          flowVariant._id !== value._id
                                      )
                                    );
                                  }}
                                >
                                  <Cancel />
                                </div>
                              }
                            />
                          );
                        })}
                      </Box>
                    );
                  },
                }}
                value={selectedFlowVariants}
                onChange={(e) => setSelectedFlowVariants(e.target.value)}
              >
                {flowVariants.map((flowVariant) => (
                  <MenuItem value={flowVariant}>{flowVariant.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          <Grid item>
            <TextField
              fullWidth
              required
              name="cronPattern"
              value={cronPattern}
              onChange={(e) => setCronPattern(e.target.value)}
              label="Cron Pattern"
              placeholder="Cron Pattern"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InfoRounded
                      sx={{ color: "#1876D2", cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          "https://crontab.guru/",
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              label="Select Timezone"
              onChange={(e) => setSelectedTimezone(e.target.value)}
              select
              required
              value={selectedTimezone}
            >
              {timezones.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              label="Error Email"
              type="name"
              name="errorEmail"
              value={errorEmail}
              onChange={(e) => setErrorEmail(e.target.value)}
              placeholder="Email id"
            />
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              label="Success Email"
              type="email"
              name="successEmail"
              value={successEmail}
              onChange={(e) => setSuccessEmail(e.target.value)}
              placeholder="Email id"
            />
          </Grid>

          <Grid
            item
            className="submit-cont"
            sx={{ justifyContent: "start", marginTop: 2 }}
          >
            <input type="submit" value="Save" />
          </Grid>
        </Grid>
      </FormControl>
      {loader && <Loader />}
    </Box>
  );
};
export default AddSchedule;
