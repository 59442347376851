import { TableBody, TableCell, TableRow } from "@mui/material";

const NoLogsFound = () => (
  <TableBody>
    <TableRow>
      <TableCell>No logs found!</TableCell>
    </TableRow>
  </TableBody>
);

export default NoLogsFound;
