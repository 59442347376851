import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";
import FlowList from "../flowList/FlowList";
import {
  drawerWidth,
  LogoutText,
  AdminText,
  RefreshText,
  ClearLogs,
  fileExplorer,
} from "../../../utils/constants";
import {
  Box,
  styled,
  Zoom,
  useTheme,
  IconButton,
  MenuIcon,
  MuiDrawer,
  MuiAppBar,
  AdminPanelSettingsIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  Divider,
  DeleteOutlineIcon,
  List,
  ListItem,
  LogoutIcon,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Tooltip,
  Toolbar,
  Typography,
  RefreshIcon,
  FolderIcon,
} from "../../common/muiImports";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebase-config";
import { toastError, toastSuccess } from "../../common/toast";
import { DescriptionOutlined, UploadFileOutlined } from "@mui/icons-material";
import GoogleSheetsListModal from "../GoogleSheetsListModal";
import UploadFileModal from "../UploadFileModal";
import { Link } from "react-router-dom";
import ClearLogsModal from "../ClearLogsModal";

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    minHeight: "46px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [isAdmin, setIsAdmin] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showGoogleSheetModal, setShowGoogleSheetModal] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);

  useEffect(() => setIsAdmin(localStorage.getItem("isAdmin") === "true"), []);

  const handleLogout = async () => {
    try {
      sessionStorage.removeItem("Auth key");
      sessionStorage.removeItem("Role");
      localStorage.removeItem("firebaseIdToken");
      await signOut(auth);
      navigate("/login");
      return toastSuccess("Logout Successfully", { autoClose: 2000 });
    } catch (err) {
      return toastError("Failed to logout!");
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        open={open}
        style={{ backgroundColor: "#fff", color: "#575757" }}
      >
        <Toolbar style={{ minHeight: "55px" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="subtitle1" noWrap component="div">
            EaConnect
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={() => setOpen(false)}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {isAdmin && (
          <>
            <List>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => navigate("/connection")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {!open ? (
                      <Tooltip
                        TransitionComponent={Zoom}
                        title="Configurations"
                        placement="right"
                      >
                        <AdminPanelSettingsIcon />
                      </Tooltip>
                    ) : (
                      <AdminPanelSettingsIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={AdminText}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
            <Divider />
          </>
        )}

        <FlowList open={open} />
        <Divider />

        <div style={{ position: "absolute", bottom: "10px", width: "100%" }}>
          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 28,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <Link
                  to="/file-explorer"
                  // href={`${REACT_APP_BACKEND_URL}/api/file-explorer/`}
                  target="_blank"
                  style={{ width: "100%", display: "flex" }}
                  rel="noreferrer"
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {!open ? (
                      <Tooltip
                        TransitionComponent={Zoom}
                        title="File Explorer"
                        placement="right"
                      >
                        <FolderIcon />
                      </Tooltip>
                    ) : (
                      <FolderIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={fileExplorer}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </Link>
              </ListItemButton>
            </ListItem>
          </List>

          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 28,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => setShowUploadFileModal(true)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {!open ? (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Upload File"
                      placement="right"
                    >
                      <UploadFileOutlined />
                    </Tooltip>
                  ) : (
                    <UploadFileOutlined />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={"Upload file"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </List>

          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 28,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => setShowGoogleSheetModal(true)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {!open ? (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Google Sheets"
                      placement="right"
                    >
                      <DescriptionOutlined />
                    </Tooltip>
                  ) : (
                    <DescriptionOutlined />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={"Google Sheets"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </List>

          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 28,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => window.location.reload()}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {!open ? (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Refresh Data"
                      placement="right"
                    >
                      <RefreshIcon />
                    </Tooltip>
                  ) : (
                    <RefreshIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={RefreshText}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </List>

          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 28,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => setShowConfirmationModal(true)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {!open ? (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Clear Logs"
                      placement="right"
                    >
                      <DeleteOutlineIcon />
                    </Tooltip>
                  ) : (
                    <DeleteOutlineIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={ClearLogs}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </List>

          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 28,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={handleLogout}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {!open ? (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Logout"
                      placement="right"
                    >
                      <LogoutIcon />
                    </Tooltip>
                  ) : (
                    <LogoutIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={LogoutText}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
        <ClearLogsModal
          showClearLogsModal={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
        />
        <UploadFileModal
          open={showUploadFileModal}
          handleClose={() => setShowUploadFileModal(false)}
        />
        <GoogleSheetsListModal
          open={showGoogleSheetModal}
          handleClose={() => setShowGoogleSheetModal(false)}
        />
      </Drawer>
    </Box>
  );
}
