import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ranged: false,
  // type sourcefile state
  sourceFiles: [],
  selectedSourceFile: null,
  fileColumns: [],
  selectedLabelField: null,
  selectedValueField: null,

  // type date state
  datePickerType: "date",
  dateOffset: "start",
  format: "MM/DD/YY",
  defaultValue: "",
  defaultFromValue: "",
  defaultToValue: "",
};

export const addConfigVarSlice = createSlice({
  name: "addConfigVar",
  initialState,
  reducers: {
    // file source reducers
    setSourceFiles: (state, action) => {
      state.sourceFiles = action.payload;
    },
    setSelectedSourceFile: (state, action) => {
      state.selectedSourceFile = action.payload;
      const found = state.sourceFiles.find((el) => el.name === action.payload);
      state.fileColumns = found.columns;
    },
    setFileColumns: (state, action) => {
      state.fileColumns = action.payload;
    },
    setSelectedLabelField: (state, action) => {
      state.selectedLabelField = action.payload;
    },
    setSelectedValueField: (state, action) => {
      state.selectedValueField = action.payload;
    },

    // date options reducers
    setDatePickerType: (state, action) => {
      state.datePickerType = action.payload;
    },
    setOffsetType: (state, action) => {
      state.dateOffset = action.payload;
    },
    setFormat: (state, action) => {
      state.format = action.payload;
    },
    setIsVariableRanged: (state, action) => {
      state.ranged = action.payload;
    },
    setDefaultValue: (state, action) => {
      state.defaultValue = action.payload;
    },
    setDefaultFromValue: (state, action) => {
      state.defaultFromValue = action.payload;
    },
    setDefaultToValue: (state, action) => {
      state.defaultToValue = action.payload;
    },
    resetState: () => initialState,
  },
});

export const {
  setSourceFiles,
  setSelectedSourceFile,
  setFileColumns,
  setSelectedLabelField,
  setSelectedValueField,
  setDatePickerType,
  setOffsetType,
  setFormat,
  setIsVariableRanged,
  setDefaultValue,
  setDefaultFromValue,
  setDefaultToValue,
  resetState,
} = addConfigVarSlice.actions;

export default addConfigVarSlice.reducer;
