import { createSlice } from "@reduxjs/toolkit";

// todo W.I.P read tasks throught global state for read and edit
const initialState = {
  tasks: [],
  selectedTask: null,
};

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    // file source reducers
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },

    setSelectedTask: (state, action) => {
      state.selectedTask = action.payload;
    },

    resetState: () => initialState,
  },
});

export const {
  setTasks,
  setSelectedTask,
  changeSelectedTaskValues,
  resetState,
} = taskSlice.actions;

export default taskSlice.reducer;
