import { http } from "./https";

export const getVariables = async () => {
  const response = await http.get("/configVar/get-variables");
  return response;
};

export const getVariableById = async (id) => {
  const response = await http.get(`/configVar/get-variable/${id}`);
  return response;
};

export const editVariable = async (id, payload) => {
  const response = await http.patch(`/configVar/${id}`, payload);
  return response;
};

export const addConfigVariable = async (payload) => {
  const response = await http.post("/configVar/add-variable", payload);
  return response;
};

// get available file names that can be used for dropdown options
export const getAvailableFiles = async () => {
  const response = await http.get("/configVar/available-files");
  return response;
};

export const deleteVariable = async (payload) => {
  const response = await http.post("/configVar/delete-variable", payload);
  return response;
};

export const getVariableDropdownOptions = async (payload) => {
  const response = await http.post("/configVar/get-dropdown-options", payload);
  return response;
};
