import { Box, Button, FormControl, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { createFlowVariant } from "../../../../api/flowVariant";
import { toastError, toastSuccess } from "../../../common/toast";

/**
 * Form component to save a flow variant
 */
const SaveFlowVariantForm = ({ handleClose }) => {
  const [formState, setFormState] = useState({ name: "", description: "" });
  const { selectedFlow, configVarValues } = useSelector((state) => state.flows);

  const handleChange = (e) => {
    setFormState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { name, description } = formState;
      const payload = {
        flow: selectedFlow._id,
        name,
        description,
        configVarValues,
      };

      await createFlowVariant(payload);
      toastSuccess("Saved");
      handleClose();
    } catch (err) {
      toastError(err.message);
      handleClose();
    }
  };
  return (
    <Box component="form" sx={{ padding: "20px" }} onSubmit={handleSubmit}>
      <FormControl fullWidth>
        <Grid container sx={{ flexDirection: "column" }} spacing={3}>
          <Grid item>
            <TextField
              fullWidth
              label="Name"
              required
              name="name"
              value={formState.name}
              onChange={handleChange}
            />
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              label="Description"
              required
              name="description"
              value={formState.description}
              onChange={handleChange}
            />
          </Grid>

          <Grid item>
            <Button fullWidth variant="contained" type="submit">
              Submit
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </Box>
  );
};

export default SaveFlowVariantForm;
