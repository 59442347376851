import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setConfigVarValues } from "../../../../app/features/flow/flowSlice";
import AutoCompleteWithColumnOptions from "./AutoComplete";
import CustomDatePicker from "./CustomDatePicker";
import CustomRangeDatePicker from "./CustomRangeDatePicker";
import RangedAutoCompleteWithColumnOptions from "./RangedAutoCompleteWithColumnOptions";
import RangedInputs from "./RangedInputs";

const ConfigVarInput = ({ configVar }) => {
  const { configVarValues } = useSelector((state) => state.flows);

  const dispatch = useDispatch();
  switch (configVar.inputType) {
    case "date":
      if (configVar.ranged) {
        return <CustomRangeDatePicker configVar={configVar} />;
      } else {
        return <CustomDatePicker configVar={configVar} />;
      }
    case "text":
      if (configVar.ranged) {
        return <RangedInputs configVar={configVar} />;
      } else {
        return (
          <TextField
            defaultValue={configVar?.defaultValue}
            label={configVar.name}
            InputLabelProps={{ shrink: "normal" }}
            value={configVarValues[configVar.name]}
            onChange={(e) => {
              const obj = {};
              obj[configVar.name] = e.target.value;
              dispatch(setConfigVarValues({ ...configVarValues, ...obj }));
            }}
          />
        );
      }

    case "dropdown":
      if (configVar.ranged) {
        return (
          <RangedAutoCompleteWithColumnOptions
            configVar={configVar}
            setConfigVarValues={setConfigVarValues}
          />
        );
      } else {
        return (
          <AutoCompleteWithColumnOptions
            configVar={configVar}
            setConfigVarValues={setConfigVarValues}
          />
        );
      }
    default:
      break;
  }
};

export default ConfigVarInput;
