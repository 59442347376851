import { Grid, TableCell, TableRow } from "@mui/material";
import { downloadFile } from "../../../api/fileExplorer";
import { REACT_APP_BACKEND_URL } from "../../../config/environment";

const DumpFilePaths = ({ nestedDumpFilePaths }) => {
  const handleClick = async (dumpFilePath) => {
    console.log(dumpFilePath);
    try {
      const { data } = await downloadFile(dumpFilePath);
      const fileName = dumpFilePath?.split("/")[1];
      console.log("got ", data);
      const href = URL.createObjectURL(data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (err) {
      console.log("error in download", err);
    }
  };
  return (
    <TableRow>
      <TableCell>
        Dumps:
        {nestedDumpFilePaths.map(({ dumpFilePath, objectName }) => (
          <Grid container spacing={2} sx={{ marginTop: "2px" }}>
            <Grid item>{objectName}</Grid>
            <Grid item>
              <span
                style={{ cursor: "pointer", color: "#007BFF" }}
                target="_blank"
                href={`${REACT_APP_BACKEND_URL}/api/file-explorer/${dumpFilePath}`}
                rel="noreferrer"
                onClick={() => handleClick(dumpFilePath)}
              >
                Download
              </span>
            </Grid>
          </Grid>
        ))}
      </TableCell>
    </TableRow>
  );
};

export default DumpFilePaths;
