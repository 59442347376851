import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { getFlowById } from "../api/flows";
import { getScheduleById } from "../api/schedule";
import { getTaskById } from "../api/task";

import ViewConnectionType from "../components/connectionType/ViewConnectiontype";
import Loader from "../components/field/loader";
import ViewFlow from "../components/flow/ViewFlow";
import ViewSchedule from "../components/schedule/ViewSchedule";
import ViewTask from "../components/task/ViewTask";
import ViewTaskType from "../components/taskType/ViewTaskType";

const ViewDetails = () => {
  const { id } = useParams();
  const {
    state: { tab, name },
  } = useLocation();

  const [loader, setLoader] = useState(false);
  const [details, setDetails] = useState([]);

  const getDetails = async (tab, id) => {
    switch (tab) {
      case "task":
        return getTaskById(id);
      case "flow":
        return getFlowById(id);
      case "schedule":
        return getScheduleById(id);
      default:
        break;
    }
  };

  useEffect(() => {
    const getdetailsType = async () => {
      try {
        setLoader(true);
        const { data } = await getDetails(tab, id);
        setDetails(data);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };
    getdetailsType();
    return () => {
      setDetails("");
    };
  }, [id, tab]);

  return (
    <div>
      <h1 className="page-head text-capitalize">{name} Details</h1>
      <div className="inner-body-cont">
        <div className="commn-table-cont table-responsive-md">
          <div className="row">
            <div className="form-group col-12">
              <table className="single-view-table table">
                <tbody>
                  {tab === "task" && <ViewTask detail={details} />}
                  {tab === "flow" && <ViewFlow detail={details} />}
                  {tab === "schedule" && <ViewSchedule detail={details} />}
                  {tab === "task-type" && <ViewTaskType detail={details} />}
                  {tab === "connection-type" && (
                    <ViewConnectionType detail={details} />
                  )}
                </tbody>
              </table>
              <Link
                to={`/${tab}/edit-${tab}/${id}`}
                className="edit-link-view view-link mt-5"
              >
                Edit
              </Link>
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </div>
  );
};

export default ViewDetails;
