import { Description } from "@mui/icons-material";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { downloadFile, getFilesInFolder } from "../../api/fileExplorer";
import { toastError } from "../common/toast";
import Loader from "../field/loader";

const ListFiles = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const { folder } = params;

  useEffect(() => {
    setLoading(true);
    const getAllFoldersInServer = async () => {
      try {
        const { data } = await getFilesInFolder(folder);
        setFiles(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toastError(err.message);
      }
    };

    getAllFoldersInServer();
  }, [folder]);

  const style = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 15,
    // display: "flex",
    textOverflow: "ellipsis",
    "text-align": "center",
  };

  const handleFileClick = async (file) => {
    //Todo Download file on click

    console.log("clicked", file);
    try {
      const { data } = await downloadFile(`${folder}/${file}`);
      console.log("got ", data);
      const href = URL.createObjectURL(data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", file); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (err) {
      console.log("error in download", err);
    }
  };
  return (
    <Box bgcolor="background.paper" sx={{ padding: 10 }} height="100vh">
      <Typography>{`~file-explorer/${folder}`}</Typography>

      <Grid
        container
        spacing={3}
        mt={4}
        //  sx={{background: 'red'}}
      >
        {files.map((file) => (
          <Tooltip title={file}>
            <Box
              sx={{
                padding: "20px",
                cursor: "pointer",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "200px",
              }}
              onClick={() => handleFileClick(file)}
            >
              <Description sx={{ fontSize: 40, color: "#1A73E8" }} />
              <Typography sx={{ width: "150px", ...style }}>{file}</Typography>
            </Box>
          </Tooltip>
        ))}
      </Grid>

      {loading && <Loader />}
    </Box>
  );
};

export default ListFiles;
