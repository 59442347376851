import React from "react";

const ViewSchedule = ({ detail }) => {
  const {
    name,
    description,
    flow,
    isActive,
    successEmail,
    errorEmail,
    cronPattern,
  } = detail;
  console.log("dete", detail);
  return (
    <>
      <tr>
        <th scope="col">Name: </th>
        <td>{name}</td>
      </tr>

      <tr>
        <th scope="col">Description</th>
        <td>{description}</td>
      </tr>
      <tr>
        <th scope="col">Flow</th>
        <td>{flow?.name}</td>
      </tr>
      <tr>
        <th scope="col">Cron Pattern: </th>
        <td>{cronPattern}</td>
      </tr>
      <tr>
        <th scope="col">Status: </th>
        <td>{isActive ? 'Active': 'Inactive'}</td>
      </tr>
      <tr>
        <th scope="col">Success Email: </th>
        <td>{successEmail}</td>
      </tr>
      <tr>
        <th scope="col">Error Email: </th>
        <td>{errorEmail}</td>
      </tr>
    </>
  );
};

export default ViewSchedule;
