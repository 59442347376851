import { TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { setConfigVarValues } from "../../../../app/features/flow/flowSlice";

const RangedInputs = ({ configVar }) => {
  const { configVarValues } = useSelector((state) => state.flows);
  const dispatch = useDispatch();

  return (
    <Stack spacing={3} direction="row">
      <TextField
        required
        InputLabelProps={{ shrink: "normal" }}
        label="From"
        value={configVarValues[`${configVar.name}_from`]}
        onChange={(e) => {
          const obj = {};
          obj[`${configVar.name}_from`] = e.target.value;
          dispatch(setConfigVarValues({ ...configVarValues, ...obj }));
        }}
      />
      <TextField
        required
        InputLabelProps={{ shrink: "normal" }}
        label="To"
        value={configVarValues[`${configVar.name}_to`]}
        onChange={(e) => {
          const obj = {};
          obj[`${configVar.name}_to`] = e.target.value;
          dispatch(setConfigVarValues({ ...configVarValues, ...obj }));
        }}
      />
    </Stack>
  );
};

export default RangedInputs;
