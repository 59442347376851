import { http } from "./https";
const { get, post } = http;

export const addSchedule = async (payload) => {
  const response = await post("/schedule/create-schedule", payload);
  return response;
};

export const getSchedules = async () => {
  const response = await get("/schedule/get-schedules");
  return response;
};

export const deleteSchedule = async (payload) => {
  const response = await post("/schedule/delete-schedule", payload);
  return response;
};

export const editSchedule = async (payload) => {
  const response = await post("/schedule/edit-schedule", payload);
  return response;
};

export const getScheduleById = async (id) => {
  const response = await get(`/schedule/get-schedule/${id}`);
  return response;
};

export const startSchedule = async (payload) => {
  const response = await post("/schedule/start-schedule", payload);
  return response;
};

export const stopSchedule = async (payload) => {
  const response = await post("/schedule/stop-schedule", payload);
  return response;
};