import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/field/loader";
import {
  deleteSchedule,
  getSchedules,
  startSchedule,
  stopSchedule,
} from "../api/schedule";
import {
  AssignmentOutlined,
  DeleteOutlined,
  PauseCircleOutlineOutlined,
  PlayCircleOutlineOutlined,
} from "@mui/icons-material";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { toastError, toastSuccess } from "../components/common/toast";

const Schedule = () => {
  const [schedules, setSchedules] = useState([]);
  const [loader, setLoader] = useState(false);

  const [itemToDelete, setItemToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  useEffect(() => {
    setLoader(true);
    const fetchSchedules = async () => {
      try {
        const response = await getSchedules();
        setLoader(false);
        setSchedules(
          response.data.reverse().map((item) => {
            return {
              ...item,
              readMore: "none",
            };
          })
        );
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };
    fetchSchedules();
  }, []);

  const handleDeleteClick = async () => {
    setLoader(true);
    const payload = {
      scheduleId: itemToDelete._id,
    };
    try {
      const result = await deleteSchedule(payload);
      setLoader(false);
      toastSuccess(result.data.message, { autoClose: 2000 });
      setShowConfirmationModal(false);
      setItemToDelete(null);
      setSchedules(schedules.filter((item) => item._id !== itemToDelete._id));
      return;
    } catch (error) {
      setLoader(false);
      return toastError(error?.message, { autoClose: 2000 });
    }
  };

  const handleStartClick = async (scheduleId) => {
    try {
      setLoader(true);
      const { data } = await startSchedule({ scheduleId });
      const foundIndex = schedules.findIndex((el) => el._id === scheduleId);
      const copy = [...schedules];
      copy[foundIndex].isActive = true;
      setSchedules(copy);

      setLoader(false);
      toastSuccess(data.message);
    } catch (e) {
      setLoader(false);
      toastError(e.message);
    }
  };

  const handleStopClick = async (scheduleId) => {
    try {
      setLoader(true);
      const { data } = await stopSchedule({ scheduleId });
      const foundIndex = schedules.findIndex((el) => el._id === scheduleId);
      const copy = [...schedules];
      copy[foundIndex].isActive = false;
      setSchedules(copy);
      toastSuccess(data.message);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      toastError(e.message);
    }
  };

  return (
    <>
      <div>
        <h1 className="page-head">Schedule </h1>
        <div className="inner-body-cont">
          <div className="btn-bloat-right">
            <Link className="commn-btn" to="/schedule/add-schedule">
              Create New
            </Link>
          </div>

          <div className="commn-table-cont table-responsive-md">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Desc.</th>
                  <th scope="col">Flow</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {schedules.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th className="first-row" scope="row">
                        {item.name}
                      </th>
                      <td className="second-row">
                        <p>
                          <span
                            style={{
                              display:
                                item.readMore === "none" ? "block" : "none",
                            }}
                            className="short-decp"
                          >
                            {item.description?.length > 150
                              ? item.description.slice(0, 150)
                              : item.description}
                          </span>
                          <span
                            style={{ display: item.readMore }}
                            className="full-text"
                          >
                            {item.description}
                          </span>
                        </p>
                      </td>
                      <td className="third-row">
                        <p>{item.flow?.name}</p>
                      </td>
                      <td className="fourth-row">
                        <Link
                          to={`/schedule/view-schedule/${item._id}`}
                          state={{ tab: "schedule", name: item.name }}
                          className="view-link"
                        >
                          <AssignmentOutlined sx={{ color: "grey" }} />
                        </Link>
                        <span
                          onClick={() => {
                            setItemToDelete(item);
                            setShowConfirmationModal(true);
                          }}
                          className="delete-link"
                        >
                          <DeleteOutlined />
                        </span>

                        <span onClick={() => handleStopClick(item._id)}>
                          {item.isActive && (
                            <PauseCircleOutlineOutlined
                              sx={{ color: "grey", ml: 3, cursor: "pointer" }}
                            />
                          )}
                        </span>
                        <span onClick={() => handleStartClick(item._id)}>
                          {!item.isActive && (
                            <PlayCircleOutlineOutlined
                              sx={{ color: "grey", ml: 3, cursor: "pointer" }}
                            />
                          )}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        title="Are you sure you want to delete this?"
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleDeleteClick={handleDeleteClick}
        message={`${
          itemToDelete?.name ? itemToDelete?.name : "Selected item"
        } will be deleted`}
      />
      {loader && <Loader />}
    </>
  );
};

export default Schedule;
