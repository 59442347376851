import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVariableDropdownOptions } from "../../../../api/config";
import { setConfigVarValues } from "../../../../app/features/flow/flowSlice";

const AutoCompleteWithColumnOptions = ({ configVar }) => {
  const { configVarValues } = useSelector((state) => state.flows);

  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);
  const { inputSourceFile, labelColumn, valueColumn } = configVar;
  useEffect(() => {
    // get options for autocomplete from inputSourceFile
    const getDropdownOptions = async () => {
      const payload = { inputSourceFile, labelColumn, valueColumn };
      const { data } = await getVariableDropdownOptions(payload);
      setOptions(data);
    };
    getDropdownOptions();
  }, [inputSourceFile, labelColumn, valueColumn]);

  return (
    <Autocomplete
      disablePortal
      options={options}
      sx={{ width: 300, pl: 2 }}
      onChange={(_event, newValue) => {
        const obj = {};
        obj[configVar.name] = newValue.value;
        dispatch(setConfigVarValues({ ...configVarValues, ...obj }));
      }}
      renderInput={(params) => {
        return <TextField {...params} label="Select option" />;
      }}
    />
  );
};

export default AutoCompleteWithColumnOptions;
