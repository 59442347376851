import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../field/loader";
import { Box, FormControl, Grid, TextField } from "@mui/material";
import { getConnectionById, updateConnection } from "../../api/connections";
import { toastError } from "../common/toast";

const EditConnection = () => {
  let navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [connectionDetails, setConnectionDetails] = useState({});
  const [updatedAttributes, setUpdatedAttributeValues] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    const getConnection = async () => {
      try {
        const { data } = await getConnectionById(id);
        console.log("data", data);
        setConnectionDetails(data);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };
    getConnection();
  }, [id]);
  console.log("state", updatedAttributes);

  const onSubmitHandler = async () => {
    setLoader(true);
    const payload = {
      description: connectionDetails.description,
      updatedAttributes,
    };

    try {
      await updateConnection(id, payload);
      setLoader(false);
      navigate("/connection");
    } catch (error) {
      setLoader(false);
      return toastError(error?.message, { autoClose: 2000 });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitHandler(e);
      }}
      bgcolor="background.paper"
      sx={{ minHeight: "90vh", padding: "20px" }}
    >
      <h1 className="page-head">Edit Connection</h1>
      <FormControl fullWidth>
        <Grid container sx={{ flexDirection: "column" }} spacing={3}>
          <Grid item>
            <TextField
              fullWidth
              label="Connection Name"
              required
              value={connectionDetails.name}
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: "normal" }}
            />
          </Grid>
          <Grid item>
            <TextField
              multiline
              required
              fullWidth
              name="description"
              label="Description"
              placeholder="Description here.."
              InputLabelProps={{ shrink: "normal" }}
              onChange={(e) =>
                setConnectionDetails({
                  ...connectionDetails,
                  description: e.target.value,
                })
              }
              value={connectionDetails.description}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label="Connection Type"
              required
              InputLabelProps={{ shrink: "normal" }}
              value={connectionDetails?.connectionType?.name}
              inputProps={{ readOnly: true }}
            />
          </Grid>
          {connectionDetails?.connectionType?.attributes?.map((item, index) => {
            return (
              <Grid item key={item.name}>
                <TextField
                  label={item.label || item.name}
                  fullWidth
                  multiline={item.inputField === "textarea"}
                  name={item.name}
                  type={item.inputField}
                  required={item.fieldRequired}
                  placeholder={`Enter Attribute value`}
                  value={updatedAttributes[item.name]}
                  onChange={(e) => {
                    const newObj = {};
                    newObj[e.target.name] = e.target.value;
                    setUpdatedAttributeValues((prevState) => ({
                      ...prevState,
                      ...newObj,
                    }));
                  }}
                />
              </Grid>
            );
          })}

          <Grid
            item
            className="submit-cont"
            style={{ justifyContent: "start", marginTop: 20 }}
          >
            <input type="submit" value="Save" />
          </Grid>
        </Grid>
      </FormControl>
      {loader && <Loader />}
    </Box>
  );
};

export default EditConnection;
