import { http } from "./https";
const { post } = http;

export const getRunStatus = async (payload) => {
  const response = await post(`/run-status/get-run-statuses`, payload);
  return response;
};

export const clearLogs = async (payload) => {
  const response = await post("/run-status/clear-logs", payload);
  return response;
};
