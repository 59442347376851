import { http } from "./https";

export const getTask = async () => {
  const response = await http.get("get-task");
  return response;
};

export const getTaskTypes = async () => {
  const response = await http.get("get-task-type");
  return response;
};

export const getTaskTypeById = async (id) => {
  const response = await http.get(`get-task-type/${id}`);
  return response;
};

export const addTask = async (payload) => {
  const response = await http.post("add-task", payload);
  return response;
};

export const getTaskById = async (id) => {
  const response = await http.get(`get-task/${id}`);
  return response;
};

export const editTask = async (id, payload) => {
  const response = await http.post(`edit-task/${id}`, payload);
  return response;
};

export const deleteTask = async (payload) => {
  const response = await http.post("delete-task", payload);
  return response;
};
