import { http } from "./https";
const { get, post } = http;

export const addConnection = async (payload) => {
  const response = await post(`add-connection`, payload);
  return response;
};

export const getConnectionType = async () => {
  const response = await get("get-connection-type");
  return response;
};

export const getConnectionTypeById = async (id) => {
  const response = await get(`get-connection-type/${id}`);
  return response;
};

export const getConnection = async () => {
  const response = await get("get-connection");
  return response;
};

export const getConnectionById = async (id) => {
  const respone = await get(`get-connection/${id}`);
  return respone;
};

export const updateConnection = async (id, payload) => {
  const response = await post(`update-connection/${id}`, payload);
  return response;
};

export const deleteConnection = async (payload) => {
  const response = await post("delete-connection", payload);
  return response;
};
