import { Route } from "react-router";
import AddConnection from "../components/connection/AddConnection";
import EditConnection from "../components/connection/EditConnection";
import AdminRoute from "../components/HOC/AdminRoute";
import ProtectedRoute from "../components/HOC/ProtectedRoute";
import Layout from "../components/layout/layout";
import Connection from "../pages/Connection";
import ViewDetails from "../pages/ViewDetails";

const ConnectionRoutes = [
  <Route
    path="/connection-type/view-connection-type/:id"
    key="/connection-type/view-connection-type/:id"
    element={
      <ProtectedRoute>
        <Layout>
          <ViewDetails />
        </Layout>
      </ProtectedRoute>
    }
  />,

  <Route
    path="/connection"
    key="/connection"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <Connection />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
  <Route
    path="/connection/edit-connection/:id"
    key="/connection/edit-connection/:id"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <EditConnection />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
  <Route
    path="/connection/:add-connection"
    key="/connection/:add-connection"
    element={
      <ProtectedRoute>
        <Layout>
          <AdminRoute>
            <AddConnection />
          </AdminRoute>
        </Layout>
      </ProtectedRoute>
    }
  />,
];

export default ConnectionRoutes;
