import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../field/loader";
import { editSchedule, getScheduleById } from "../../api/schedule";
import { getFlow } from "../../api/flows";
import { toastError, toastSuccess } from "../common/toast";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { Cancel, InfoRounded } from "@mui/icons-material";
import { getVariantsByFlowId } from "../../api/flowVariant";
import { timezones } from "../../utils/constants";
const EditSchedule = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [scheduleDetails, setScheduleDetails] = useState({});
  const [loader, setLoader] = useState(true);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [flows, setFlows] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [selectedFlowVariants, setSelectedFlowVariants] = useState([]);
  const [allFlowVariants, setAllFlowVariants] = useState([]);
  const [flowVariantLabelMapping, setFlowVariantLabelMapping] = useState({});

  useEffect(() => {
    setLoader(true);
    const getSchedule = async () => {
      try {
        const { data } = await getScheduleById(id);
        setLoader(false);
        setScheduleDetails(data);
        setSelectedFlowVariants(
          data.flowVariants ? data.flowVariants?.map((el) => el._id) : []
        );
        setSelectedFlow(data.flow._id);
        const { data: allFlowVariantsResult } = await getVariantsByFlowId(
          data.flow._id
        );
        setSelectedTimezone(data.timezone);
        setAllFlowVariants(allFlowVariantsResult);

        // store a map for task label -> task id
        const flowVariantIdToLabelMap = {};

        for (const flowVariant of allFlowVariantsResult) {
          flowVariantIdToLabelMap[flowVariant._id] = flowVariant.name;
        }
        setFlowVariantLabelMapping(flowVariantIdToLabelMap);
      } catch (error) {
        setLoader(false);
        toastError(error?.response?.data?.message?.toString() || error.message);
      }
    };
    getSchedule();
    const fetchFlow = async () => {
      const { data } = await getFlow();
      setLoader(false);
      setFlows(data);
    };
    fetchFlow();
  }, [id]);

  const onSubmitHandler = async () => {
    // setLoader(true);
    scheduleDetails.flow = selectedFlow;

    const { description, cronPattern, successEmail, errorEmail } =
      scheduleDetails;

    const payload = {
      scheduleId: id,
      description,
      flow: selectedFlow,
      cronPattern,
      successEmail,
      errorEmail,
      flowVariants: selectedFlowVariants,
      timezone: selectedTimezone,
    };
    // return;
    try {
      const result = await editSchedule(payload);
      setLoader(false);
      navigate("/schedule");
      return toastSuccess(result.data.message);
    } catch (error) {
      setLoader(false);
      return toastError(error?.message);
    }
  };

  const handleFlowChange = (e) => {
    setSelectedFlow(e.target.value);
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitHandler(e);
      }}
      bgcolor="background.paper"
      sx={{ minHeight: "90vh", padding: "20px" }}
    >
      <h1 className="page-head">Edit Schedule</h1>
      <FormControl fullWidth>
        <Grid container sx={{ flexDirection: "column" }} spacing={3}>
          <Grid item>
            <TextField
              fullWidth
              label="name"
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: "normal" }}
              value={scheduleDetails.name}
            />
          </Grid>

          <Grid item>
            <TextField
              multiline
              required
              label="Description"
              fullWidth
              placeholder="Description here.."
              InputLabelProps={{ shrink: "normal" }}
              onChange={(e) =>
                setScheduleDetails({
                  ...scheduleDetails,
                  description: e.target.value,
                })
              }
              value={scheduleDetails?.description}
            />
          </Grid>

          <Grid item>
            <TextField
              onChange={handleFlowChange}
              InputLabelProps={{ shrink: "normal" }}
              required
              label="Select Flow"
              select
              fullWidth
              value={selectedFlow}
            >
              {flows.map((item) => (
                <MenuItem
                  value={item._id}
                  onClick={(e) => setSelectedFlow(e.target.value)}
                >
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {!!scheduleDetails?.flowVariants?.length &&
            !!allFlowVariants?.length && (
              <Grid item>
                <TextField
                  name="flowVariants"
                  label="Select flow variants"
                  select
                  required
                  fullWidth
                  placeholder="Please Select"
                  SelectProps={{
                    multiple: true,
                    renderValue: (selected) => {
                      return (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => {
                            return (
                              <Chip
                                key={value}
                                label={flowVariantLabelMapping[value]}
                                onDelete={() => {}}
                                deleteIcon={
                                  <div
                                    onMouseDown={(e) => {
                                      e.stopPropagation();
                                      setSelectedFlowVariants((prevState) =>
                                        prevState.filter(
                                          (flowVariantId) =>
                                            flowVariantId !== value
                                        )
                                      );
                                    }}
                                  >
                                    <Cancel />
                                  </div>
                                }
                              />
                            );
                          })}
                        </Box>
                      );
                    },
                  }}
                  value={selectedFlowVariants}
                  onChange={(e) => setSelectedFlowVariants(e.target.value)}
                >
                  {allFlowVariants?.map((flowVariant) => (
                    <MenuItem value={flowVariant._id} key={flowVariant._id}>
                      {flowVariant.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

          <Grid item>
            <TextField
              label="Cron Pattern"
              required
              name="cronPattern"
              InputLabelProps={{ shrink: "normal" }}
              fullWidth
              placeholder="Cron Pattern"
              onChange={(e) =>
                setScheduleDetails({
                  ...scheduleDetails,
                  cronPattern: e.target.value,
                })
              }
              value={scheduleDetails?.cronPattern}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InfoRounded
                      sx={{ color: "#1876D2", cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          "https://crontab.guru/",
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              label="Select Timezone"
              onChange={(e) => setSelectedTimezone(e.target.value)}
              select
              required
              value={selectedTimezone}
            >
              {timezones.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item>
            <TextField
              label="Error Email"
              name="error_Email"
              InputLabelProps={{ shrink: "normal" }}
              fullWidth
              placeholder="Email id"
              onChange={(e) =>
                setScheduleDetails({
                  ...scheduleDetails,
                  errorEmail: e.target.value,
                })
              }
              value={scheduleDetails?.errorEmail}
            />
          </Grid>

          <Grid item>
            <TextField
              label="Success Email"
              type="name"
              InputLabelProps={{ shrink: "normal" }}
              fullWidth
              name="success_Email"
              placeholder="Email id"
              onChange={(e) =>
                setScheduleDetails({
                  ...scheduleDetails,
                  successEmail: e.target.value,
                })
              }
              value={scheduleDetails.successEmail}
            />
          </Grid>

          <Grid
            item
            sx={{ marginTop: 2, justifyContent: "start" }}
            className="submit-cont"
          >
            <input type="submit" value="Save" />
          </Grid>
        </Grid>
      </FormControl>
      {loader && <Loader />}
    </Box>
  );
};

export default EditSchedule;
