import { http } from "./https";

export const getAllFolders = async () => {
  const response = await http.get(`file-explorer`);
  return response;
};

export const getFilesInFolder = async (folder) => {
  const response = await http.get(`file-explorer/${folder}`);
  return response;
};

export const downloadFile = async (path) => {
  const response = await http.get(`file-explorer/${path}`, {
    responseType: "blob",
  });
  return response;
};
