import * as React from "react";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { setConfigVarValues } from "../../../../app/features/flow/flowSlice";

export default function CustomRangeDatePicker({ configVar }) {
  const [fromValue, setFromValue] = React.useState(dayjs(Date.now()));
  const [toValue, setToValue] = React.useState(dayjs(Date.now()));

  const { configVarValues } = useSelector((state) => state.flows);
  const dispatch = useDispatch();
  // set standard display format
  let inputDisplayFormat = "DD/MM/YYYY";

  if (configVar.datePickerType === "month") {
    inputDisplayFormat = "MMM YYYY";
  }

  if (configVar.datePickerType === "year") {
    inputDisplayFormat = "YYYY";
  }

  const handleFromChange = (newValue) => {
    setFromValue(newValue);
    const obj = {};
    // set user config display format for actual value
    obj[`${configVar.name}_from`] = dayjs(newValue).format(configVar.format);
    dispatch(setConfigVarValues({ ...configVarValues, ...obj }));
  };

  const handleToChange = (newValue) => {
    setToValue(newValue);
    const obj = {};
    // set user config display format for actual value
    obj[`${configVar.name}_to`] = dayjs(newValue).format(configVar.format);
    dispatch(setConfigVarValues({ ...configVarValues, ...obj }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3} direction="row">
        <DesktopDatePicker
          views={[
            configVar.datePickerType === "date"
              ? "day"
              : configVar.datePickerType,
          ]}
          openTo={"month"}
          label="From"
          inputFormat={inputDisplayFormat}
          value={configVarValues[`${configVar.name}_from`]}
          onChange={handleFromChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <DesktopDatePicker
          views={[
            configVar.datePickerType === "date"
              ? "day"
              : configVar.datePickerType,
          ]}
          openTo={"month"}
          label="To"
          inputFormat={inputDisplayFormat}
          value={configVarValues[`${configVar.name}_to`]}
          onChange={handleToChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
}
