import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../field/loader";
import { Box, FormControl, Grid, TextField } from "@mui/material";
import { toastError } from "../common/toast";
import { editVariable, getVariableById } from "../../api/config";

const EditConfigVar = () => {
  let navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [configVar, setConfigVar] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    const getVar = async () => {
      try {
        const { data } = await getVariableById(id);
        setConfigVar(data);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };
    getVar();
  }, [id]);
  // console.log("state", updatedAttributes);

  const onSubmitHandler = async () => {
    setLoader(true);

    try {
      await editVariable(id, {
        defaultValue: configVar.defaultValue,
        defaultFromValue: configVar?.defaultFromValue,
        defaultToValue: configVar?.defaultToValue,
      });
      setLoader(false);
      navigate("/configVar");
    } catch (error) {
      setLoader(false);
      return toastError(error?.message, { autoClose: 2000 });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitHandler(e);
      }}
      bgcolor="background.paper"
      sx={{ minHeight: "90vh", padding: "20px" }}
    >
      <h1 className="page-head">Edit Config Variable</h1>
      <FormControl fullWidth>
        <Grid container sx={{ flexDirection: "column" }} spacing={3}>
          <Grid item>
            <TextField
              fullWidth
              label="Variable Name"
              required
              value={configVar.name}
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: "normal" }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label="Variable Type"
              required
              InputLabelProps={{ shrink: "normal" }}
              value={configVar?.inputType}
              inputProps={{ readOnly: true }}
            />
          </Grid>

          {!configVar.ranged && (
            <Grid item>
              <TextField
                fullWidth
                label="Default Value"
                required
                InputLabelProps={{ shrink: "normal" }}
                value={configVar?.defaultValue}
                onChange={(e) =>
                  setConfigVar((prevState) => ({
                    ...prevState,
                    defaultValue: e.target.value,
                  }))
                }
              />
            </Grid>
          )}

          {configVar.ranged && (
            <Grid item>
              <Grid item>
                <FormControl fullWidth>
                  <Grid container spacing={2}>
                    <Grid item>
                      <TextField
                        fullWidth
                        type="name"
                        required
                        label="Default from value"
                        name="defaultFromValue"
                        value={configVar?.defaultFromValue}
                        onChange={(e) =>
                          setConfigVar((prevState) => ({
                            ...prevState,
                            defaultFromValue: e.target.value,
                          }))
                        }
                        placeholder="Default variable value"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        type="name"
                        required
                        label="Default to value"
                        name="defaultToValue"
                        value={configVar?.defaultToValue}
                        onChange={(e) =>
                          setConfigVar((prevState) => ({
                            ...prevState,
                            defaultToValue: e.target.value,
                          }))
                        }
                        placeholder="Default variable value"
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
          )}

          <Grid
            item
            className="submit-cont"
            style={{ justifyContent: "start", marginTop: 20 }}
          >
            <input type="submit" value="Save" />
          </Grid>
        </Grid>
      </FormControl>
      {loader && <Loader />}
    </Box>
  );
};

export default EditConfigVar;
