import { http } from "./https";
const { get } = http;

export const uploadFile = async (payload) => {
  const response = await http.post(`upload-file`, payload, {});
  return response;
};

export const getSageObjectNames = async () => {
  const response = await get("sage-metadata/objects");
  return response;
};
