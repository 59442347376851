import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { recordsProcessedWithinDuration } from "../../api/flows";
import { kFormatter } from "../../utils/formatters";
import {
  LocalizationProvider,
  DesktopDatePicker,
  AdapterDateFns,
  TextField,
  Stack,
  Grid,
  Paper,
  Box,
  Typography,
} from "../common/muiImports";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import subDays from "date-fns/subDays";
import {
  filterFromDate,
  filterToDate,
} from "../../app/features/runStatus/runStatusSlice";
import { Chip } from "@mui/material";

export default function FilterDate() {
  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState(startOfDay(subDays(new Date(), 7)));
  const [toDate, setToDate] = useState(endOfDay(new Date()));
  const [recordsProcessed, setRecordsProcessed] = useState(0);
  const handelFromDate = (date) => {
    setFromDate(startOfDay(date));
    dispatch(filterFromDate(startOfDay(date)));
  };

  const handleToDate = (date) => {
    dispatch(filterToDate(new Date(date).getTime()));
    setToDate(endOfDay(date));
    dispatch(filterToDate(endOfDay(date)));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (fromDate && toDate) {
        const { data } = await recordsProcessedWithinDuration(fromDate, toDate);
        setRecordsProcessed(kFormatter(data.recordsProcessed));
      }
    };

    fetchData();
  }, [fromDate, toDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              mb: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p className="monitor-table-head">Filter Run status </p>

              <Stack spacing={3} direction="row">
                <DesktopDatePicker
                  label="From"
                  inputFormat="MM/dd/yyyy"
                  value={fromDate}
                  disableFuture
                  onChange={handelFromDate}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DesktopDatePicker
                  label="To"
                  inputFormat="MM/dd/yyyy"
                  value={toDate}
                  onChange={handleToDate}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ ml: 2 }}
                />
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Chip
                label={recordsProcessed}
                color="primary"
                sx={{ fontSize: "22px", fontWeight: 600 }}
              />
              <Typography id="transition-modal-title" sx={{ fontWeight: 400 }}>
                Records processed
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Stack>
    </LocalizationProvider>
  );
}
