import { createSlice } from "@reduxjs/toolkit";

// flow execution and flow list state
const initialState = {
  flowList: [],
  selectedFlow: null,
  selectedFlowVariant: null,
  configVarValues: {},
  variants: [],
};

export const flowSlice = createSlice({
  name: "flow",
  initialState,
  reducers: {
    setFlows: (state, action) => {
      state.flowList = action.payload;
    },
    setSelectedFlow: (state, action) => {
      state.selectedFlow = action.payload;

      // check for configVarValues default values and add them
      const defaultValues = {};
      action.payload?.configVars?.forEach((configVar) => {
        if (configVar.ranged) {
          defaultValues[`${configVar?.name}_from`] =
            configVar?.defaultFromValue;
          defaultValues[`${configVar?.name}_to`] = configVar?.defaultToValue;
        } else {
          defaultValues[configVar?.name] = configVar?.defaultValue;
        }
      });
      state.configVarValues = defaultValues;
    },
    setSelectedFlowVariant: (state, action) => {
      const variantId = action.payload;

      const found = state?.variants?.find((el) => el._id === variantId);
      state.selectedFlowVariant = found;
      state.configVarValues = found.configVarValues;
    },
    setConfigVarValues: (state, action) => {
      state.configVarValues = action.payload;
    },
    setVariants: (state, action) => {
      state.variants = action.payload;
    },
  },
});

export const {
  setFlows,
  setSelectedFlow,
  setSelectedFlowVariant,
  setConfigVarValues,
  setVariants,
} = flowSlice.actions;

export default flowSlice.reducer;
