import { Autocomplete, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVariableDropdownOptions } from "../../../../api/config";
import { setConfigVarValues } from "../../../../app/features/flow/flowSlice";

const RangedAutoCompleteWithColumnOptions = ({ configVar }) => {
  const [options, setOptions] = useState([]);
  const { inputSourceFile, labelColumn, valueColumn } = configVar;

  const { configVarValues } = useSelector((state) => state.flows);
  const dispatch = useDispatch();

  useEffect(() => {
    // get options for autocomplete from inputSourceFile
    const getDropdownOptions = async () => {
      const payload = { inputSourceFile, labelColumn, valueColumn };
      const { data } = await getVariableDropdownOptions(payload);
      setOptions(data);
    };
    getDropdownOptions();
  }, [inputSourceFile, labelColumn, valueColumn]);

  return (
    <Stack spacing={3} direction="row" sx={{ width: "100% " }}>
      <Autocomplete
        disablePortal
        options={options}
        sx={{ width: "50%" }}
        onChange={(_event, newValue) => {
          const obj = {};
          obj[`${configVar.name}_from`] = newValue.value;
          dispatch(setConfigVarValues({ ...configVarValues, ...obj }));
        }}
        renderInput={(params) => {
          return <TextField {...params} label="Select from" />;
        }}
      />
      <Autocomplete
        disablePortal
        options={options}
        sx={{ width: "50%" }}
        onChange={(_event, newValue) => {
          const obj = {};
          obj[`${configVar.name}_to`] = newValue.value;
          dispatch(setConfigVarValues({ ...configVarValues, ...obj }));
        }}
        renderInput={(params) => {
          return <TextField {...params} label="Select To" required />;
        }}
      />
    </Stack>
  );
};

export default RangedAutoCompleteWithColumnOptions;
