import { Folder } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getAllFolders } from "../../api/fileExplorer";
import { toastError } from "../common/toast";
import Loader from "../field/loader";
const style = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  fontSize: 15,
  display: "flex",
  textOverflow: "ellipsis",
  justifyContent: "center",
};

const FileExplorer = () => {
  // folders in public dir in server
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllFoldersInServer = async () => {
      setLoading(true);
      try {
        const { data } = await getAllFolders();

        setFolders(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toastError(err.message);
      }
    };

    getAllFoldersInServer();
  }, []);
  const navigate = useNavigate();
  return (
    <Box bgcolor="background.paper" sx={{ padding: 10 }} height="100vh">
      <Typography>File Explorer</Typography>
      <Grid
        container
        mt={3}
        spacing={3}
        //  sx={{background: 'red'}}
      >
        {folders.map((file) => (
          <Box
            sx={{
              padding: "20px",
              cursor: "pointer",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={() => navigate(`/file-explorer/${file}`)}
          >
            <Folder sx={{ fontSize: 60, color: "#F8D775" }} />
            <Typography sx={{ ...style }}>{file}</Typography>
          </Box>
        ))}
      </Grid>
      {loading && <Loader />}
    </Box>
  );
};

export default FileExplorer;
