import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { downloadFile } from "../../../api/fileExplorer";
import { setSelectedTaskStatus } from "../../../app/features/runStatus/runStatusSlice";

//TODO update download logic
const columns = [
  { field: "taskName", headerName: "Task Name", width: 230 },
  { field: "status", headerName: "Status", width: 230 },
  { field: "startTime", headerName: "Start Time", width: 230 },
  { field: "endTime", headerName: "End Time", width: 230 },
  {
    field: "dumpFilePath",
    headerName: "Dump file",
    width: 230,
    renderCell: (params) => {
      // download file through browser on click
      const handleClick = async (dumpFilePath) => {
        console.log(dumpFilePath);
        try {
          const { data } = await downloadFile(dumpFilePath);
          const fileName = dumpFilePath?.split("/")[1];
          console.log("got ", data);
          const href = URL.createObjectURL(data);

          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", fileName); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        } catch (err) {
          console.log("error in download", err);
        }
      };
      return params.formattedValue ? (
        <span
          style={{ cursor: "pointer", color: '#007DFF' }}
          onClick={() => handleClick(params.formattedValue)}
        >
          Check dump
        </span>
      ) : (
        "N/A"
      );
    },
  },
];

const Tasks = () => {
  const taskStatuses = useSelector((state) => state.runStatuses.taskStatuses);
  const selectedTaskStatus = useSelector(
    (state) => state.runStatuses.selectedTaskStatus
  );

  const dispatch = useDispatch();

  const handleClick = ({ row }) => {
    dispatch(setSelectedTaskStatus(row.id));
  };

  return (
    <div className="monitor-table-cont">
      <h5 className="monitor-table-head">Tasks </h5>
      <div style={{ height: 400, width: "100%" }}>
        {taskStatuses && (
          <DataGrid
            rows={taskStatuses.map((el) => {
              return {
                id: el._id,
                ...el,
                startTime: el.startTime
                  ? new Date(el.startTime).toLocaleString()
                  : "N/A",
                endTime: el.endTime
                  ? new Date(el.endTime).toLocaleString()
                  : "N/A",
              };
            })}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            onCellClick={handleClick}
            selectionModel={selectedTaskStatus ? selectedTaskStatus._id : ""}
          />
        )}
      </div>
    </div>
  );
};

export default Tasks;
