import { Route } from "react-router";
import FileExplorer from "../components/FileExplorer";
import ListFiles from "../components/FileExplorer/ListFiles";
import ProtectedRoute from "../components/HOC/ProtectedRoute";

const FileExplorerRoutes = [
  <Route
    path="/file-explorer"
    key="/file-explorer"
    element={
      <ProtectedRoute>
        <FileExplorer />
      </ProtectedRoute>
    }
  />,

  <Route
    path="/file-explorer/:folder"
    key="/file-explorer"
    element={
      <ProtectedRoute>
        <ListFiles />
      </ProtectedRoute>
    }
  />,
];

export default FileExplorerRoutes;
